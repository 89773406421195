import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import Requests from "../Requests.js";

function Header() {

    //TODO implement the user details to correctly show their name

    let [userFName, setUserFName] = useState(null);
    let [userLName, setUserLName] = useState(null);

    useEffect(()=>{
        Requests.getCurrentUser().then( data => {
            setUserLName(data.data.last_name);
            setUserFName(data.data.first_name);
        });
    },[])


    return (
        <header>
            <div className="header_inner_wrapper">
            <p className="red-text dates">Faculty Writing Challenge</p>
            {userFName !== null && <div><p className={"hello-text"}>{userFName + " " + userLName}</p></div>}
           </div>

            {/*TODO remove this menu*/}
            {/* <div style={{
                position: 'fixed',
                zIndex: '100',
                top: '10px',
                left: '10px',
                display: 'flex',
                background: 'rgba(255,255,0,0.7)',
                textShadow: '2px 2px 0 white',
                fontSize: '16pt',
                padding: '5px'
            }}>
                <Link style={{marginRight: '1em', color: 'black'}} to={'/open'}>Upcoming Challenge</Link>
                <Link style={{marginRight: '1em', color: 'black'}} to={'/register'}>Register</Link>
                <Link style={{marginRight: '1em', color: 'black'}} to={'/holding'}>Holding</Link>
                <Link style={{marginRight: '1em', color: 'black'}} to={'/welcome'}>Welcome</Link>
                <Link style={{marginRight: '1em', color: 'black'}} to={'/progress'}>Progress</Link>
                <Link style={{marginRight: '1em', color: 'black'}} to={'/timer'}>Timer</Link>
                <Link style={{marginRight: '1em', color: 'black'}} to={'/asdf'}>Error</Link>
            </div> */}



        </header>
    );
}

export default Header;
