import React, {useContext, useEffect, useState} from 'react';
import {useHistory, useParams} from "react-router-dom"

import '../assets/css/certificate.css';
import logo from '../assets/images/bsu_logo_black.svg';
import timer from '../assets/images/timer_start_bg.svg';
import Animation from "./Animation";
import Requests from "../Requests";
import {UserConsumer, UserContext} from "../model/User";

function Certificate() {

    const user = useContext(UserContext).user;
    const {challengeId} = useParams();
    const [completedChallenge, setCompletedChallenge] = useState(null)
    const history = useHistory();

    useEffect(()=>{
        console.log(user);
        if(!user)return;
        let completedChallenge = user.events.reduce((completedChallenge,currentChallenge)=>{
            if(currentChallenge.event_id == challengeId ){
                return currentChallenge;
            }
            return completedChallenge;
        },null)
        if(completedChallenge==null){
            history.push("/");
            return;
        }
        setCompletedChallenge(completedChallenge);
    },[user]);




    if(!completedChallenge){
        return null;
    }


    return (
        <UserConsumer>
            {({user, updateUser}) => (
                user &&
                <div className="certificate-container">
                    <div className="certificate">
                        <div className="top">
                            <h2>Certificate of Completion</h2>
                            <p>This certifies that</p>
                        </div>
                        <h1>{user.user_info.first_name} {user.user_info.last_name}</h1>
                        <div>
                            <p>{true ? 'has completed' : 'participated in'} the <b>{completedChallenge.event_name} Writing Challenge</b><br/> at Ball State University</p>
                            <ul>
                                <li>Total writing time: <time>{(completedChallenge.user_results.total_seconds / 60 /60).toFixed(2)} hours</time></li>
                                <li>Total word count: <output>{completedChallenge.user_results.total_words.toLocaleString()}</output></li>
                            </ul>
                        </div>
                        <p><img className="logo" src={logo} alt="Ball State University" /></p>
                        <Animation isTimerCentered={false} isCountingDown={false}/>
                    </div>
                </div>
        )}</UserConsumer>
	);
}

export default Certificate;