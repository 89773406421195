import React from 'react';

import {API_URL} from "../config/config";

import linkedin_icon from "../assets/images/linkedin_icon.svg";
import twitter_icon from "../assets/images/twitter_icon.svg";
import facebook_icon from "../assets/images/facebook_icon.svg";
import moment from "moment";

function Share(props) {
	const year = moment(props.challenge.start_date).format("Y");

	const challengeID = props.challenge.id ? props.challenge.id : props.challenge.event_id;

	const shareType = ( ['registered','participating','participated'] ) ? props.type : 'participated';
	const shareURL = API_URL+'share/'+challengeID+'/'+shareType;

	const openSharingPopup = e => {
		e.preventDefault();

		const width = 650;
		const height = 450;

		const url = e.currentTarget.href;

		window.open(url, 'Share', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,width='+width+',height='+height+',top='+(window.screen.height/2-height/2)+',left='+(window.screen.width/2-width/2));

		return false;
	};

	const classes = [
		props.largeMargin ? 'margin-top-large' : 'margin-top',
		'border-top-gray'
	];

	let message = 'Share your accomplishments on social media!';
	if( props.type === 'registered' ){
		message = 'Invite your BSU colleagues on social media.';
	}
	if( props.type === 'participating' ){
		message = 'Share this challenge on social media!';
	}


	return (
		<div className={classes.join(' ')}>
			<p><i>{message}</i></p>
			<ul className="social-media-list">
				<li>
					<a onClick={openSharingPopup} target="_blank" href={'https://www.linkedin.com/shareArticle?mini=true&url='+shareURL}>
					<img src={linkedin_icon} alt=""/>
					</a>
				</li>
				<li>
					<a onClick={openSharingPopup} target="_blank" href={'https://twitter.com/share?url='+shareURL}>
					<img src={twitter_icon} alt=""/>
					</a>
				</li>
				<li>
					<a onClick={openSharingPopup} target="_blank" href={'https://www.facebook.com/sharer/sharer.php?u='+shareURL}>
					<img src={facebook_icon} alt=""/>
					</a>
				</li>
			</ul>
		</div>
	);
}

export default Share;
