import React from 'react';
import ballstate_logo from '../assets/images/bsu_logo_white.svg';

function Footer() {
    const today = new Date();
    return (
        <footer>
            <div className="footer_inner_wrapper flex-row justify_between">
                <p className="white-text">
                    Copyright © {today.getFullYear()} Ball State University
                </p>
                <a href="http://bsu.edu" target="_new"><img src={ballstate_logo} alt="Ball State University"/></a>
            </div>
        </footer>
    );
}

export default Footer;