import React,{useState, useEffect} from "react";
import Request from "../Requests";
import pencil_icon_large from "../assets/images/pencil_icon_large.svg";
import ReactGA from "react-ga";

const Goal = ()=>{
    const [isEditing, setIsEditing] = useState(false);
    const [goal, setGoal] = useState(null);
    const [editGoalText, setEditGoalText] = useState(null)

    useEffect(()=>{
        Request.getGoal().then(response=>{
           setGoal(response.data.goal);
           setEditGoalText(response.data.goal);
        });
    },[]);

    const onSubmit = ()=>{
        Request.updateGoal(editGoalText).then(response=>{
            setGoal(editGoalText);
            setIsEditing(false);
        });
    };

    const onCancel = ()=>{
        setIsEditing(false);
        setEditGoalText(goal);
    };

    const startEditing = () => {
        ReactGA.event({
            category: 'Goals',
            action: 'Click',
            label: 'Edit Button'
        });
        setIsEditing(true);
    };

    // return "hello";
    if(goal==null) {
        return null;
    }
    if(isEditing){
        return(
            <form onSubmit={e=>{e.preventDefault();onSubmit()}} className="goal goal-update">
                <input type="text" value={editGoalText} onChange={(e)=>{
                    if (e.target.value.length === 1) {
                        ReactGA.event({
                            category: 'Goals',
                            action: 'Type',
                            label: 'First Character Only'
                        });
                    }
                    setEditGoalText(e.target.value);
                }}/>
                <button className="button-small margin_left_button" type="submit">Submit</button>
                <button className="button-small margin_left_button" onClick={(e)=>{e.preventDefault();onCancel();}} type="button">Cancel</button>
            </form>
        )
    }
    return(
        <div className="goal goal-normal">
            <span className={"goal-text margin-bottom-med"}>"{goal}"</span>
            <button onClick={()=>{startEditing()}}><img className={"edit-pencil"} src={pencil_icon_large} alt={"Edit team name"}/>Edit goal</button>
        </div>
    )
};

export default Goal;