import axios from "axios";
import {API_URL, DEVELOPMENT_MODE, DEVELOPMENT_MODE_SESSION_KEY, SHIBBOLETH_URL} from "./config/config";


let API_KEY_HEADER = {
    headers: {
        "Session-Key": DEVELOPMENT_MODE ? DEVELOPMENT_MODE_SESSION_KEY : localStorage.getItem("challengeSessionKey"),
    }
};

axios.interceptors.response.use(r => r, err => {
    if (err.response.status === 499 || err.response.status === 401) {
        localStorage.removeItem("challengeSessionKey");
        window.location = SHIBBOLETH_URL
    }
    return Promise.reject(err.response.status);
});

export default class Request {

    //LOGIN / PASSWORD

    static isLoggedIn() {
        let session_key = localStorage.getItem("challengeSessionKey");
        return (session_key !== null);
    }

    static getBSUDepartments() {
        return axios.get("https://prod-digitalcorps.servers.bsu.edu/tools/bsu_departments/")
    }

    static getOpenRegistrationChallenge() {
        return axios.get(API_URL + "events/open_registration", API_KEY_HEADER)
    }

    static getBSUOffices() {
        return axios.get("https://prod-digitalcorps.servers.bsu.edu/tools/bsu_offices/")
    }

    static getCurrentUser() {
        return axios.get(API_URL + "users/current", API_KEY_HEADER);
    }

    static updateCurrentUser(userData) {
        return axios.put(API_URL + "users/current", userData, API_KEY_HEADER);
    }

    static registerUserToChallenge(data) {
        return axios.post(API_URL + "users/current/register_for_current_event", data, API_KEY_HEADER)
    }

    static getActiveChallenge() {
        return axios.get(API_URL + "events/active", API_KEY_HEADER)
    }

    static getUpcomingChallenges() {
        return axios.get(API_URL + "events/upcoming", API_KEY_HEADER)
    }

    static getPastChallenges() {
        return axios.get(API_URL + "events/past", API_KEY_HEADER)
    }

    static getUserCurrentTeam() {
        return axios.get(API_URL + "teams/event/current", API_KEY_HEADER)
    }

    static getTeamMembers(teamId) {
        return axios.get(API_URL + "teams/" + teamId + "/members", API_KEY_HEADER)
    }

    static getHistory() {
        return axios.get(API_URL + "users/current/history", API_KEY_HEADER)
    }

    static getChallenge(challengeId) {
        return axios.get(API_URL + "events/" + challengeId, API_KEY_HEADER)
    }

    static getAnnouncement() {
        return axios.get(API_URL + "announcements", API_KEY_HEADER);
    }

    static getRegistrations(user_id) {
        return axios.get(API_URL + "users/" + user_id + "/registrations", API_KEY_HEADER);
    }

    static postSurveyData(userData) {
        return axios.post(API_URL + "surveys", userData, API_KEY_HEADER);
    }

    static updateTeamName(teamName) {
        return axios.put(API_URL + "teams/event/current", {name: teamName}, API_KEY_HEADER);
    }

    static getMessages(eventId){
        return axios.get(API_URL+"events/"+eventId+"/messages", API_KEY_HEADER);
    }

    static readMessages(receiverId){
        if(!receiverId){
            return axios.post(API_URL+"messages/read",{},API_KEY_HEADER)
        }else{
            return axios.post(API_URL+"messages/read/"+receiverId,{},API_KEY_HEADER)

        }
    }

    static createMessage(teamId, receiverId, message){
        return axios.post(API_URL+"messages",{team_id:teamId, receiver_id:receiverId, message}, API_KEY_HEADER);
    }

    static updateGoal(goal){
        return axios.put(API_URL+"goal",{goal}, API_KEY_HEADER);
    }

    static getGoal(){
        return axios.get(API_URL+"goal", API_KEY_HEADER);
    }

    static getAchievementsForChallenge(challengeId){
        return axios.get(API_URL + `achievements/${challengeId}`, API_KEY_HEADER);
    }

}
