import React, {useEffect, useRef, useState} from 'react';
import Achievement from "./Achievement";
import Requests from "../Requests";
import ReactGA from "react-ga";
import NewAchievement, {achievements} from "./NewAchievement";
//57
//move this to a data file later
const generateAllAchievementsObject = () => {
    const teamCodes = ['TWD', 'TTD', 'TTT', 'TWT']
    const AllAchievementsObject = [];
    const users = [
        {user_id: 1, team_id: 1, user_name: 'Drew Heiss', team_name: 'ALPHAS'},
        {user_id: 2, team_id: 2, user_name: 'Tyler Newlin', team_name: 'ALPHAS'},
        {user_id: 3, team_id: 3, user_name: 'Mike Jones', team_name: 'ALPHAS'},
        {user_id: 4, team_id: 4, user_name: 'John Smith', team_name: 'ALPHAS'},
    ];

    const teams = [
        {team_id: 1, team_name: 'ALPHAS'},
        {team_id: 2, team_name: 'DEVILS'},
        {team_id: 3, team_name: 'BRAVOS'},
        {team_id: 4, team_name: 'CHARLIES'},
    ]

    const createdAt = '2023-07-11 09:19:43';

    Object.keys(achievements).forEach(code => {
        const startingCode = code.substring(0, 3);
        if(teamCodes.includes(startingCode)) {
            teams.forEach(team => {
                AllAchievementsObject.push({
                    code,
                    created_at: createdAt,
                    user_id: null,
                    team_id: team.team_id,
                    user_name: null,
                    team_name: team.team_name
                });
            });
        } else {
            users.forEach(user => {
                AllAchievementsObject.push({
                    code,
                    created_at: createdAt,
                    user_id: user.user_id,
                    team_id: user.team_id,
                    user_name: user.user_name,
                    team_name: user.team_name
                });
            });
        }

    });

    return AllAchievementsObject;
}

const maxNumberOfSecondsBeforeCheckingMessages = 60 * 60


function Achievements({userId, teamId, challengeId, isVisible}) {

    let [achievements, setAchievements] = useState(null);
    let [hasScrolled, setHasScrolled] = useState(false);

    let timer = useRef(null);
    let [refershTimeInSeconds, setRefreshTimeInSeconds] = useState(0);

    const loadAchievements = () => {
        Requests.getAchievementsForChallenge(challengeId).then((response) => {
            //if in test achievements mode then dont do this set it to AllAchievementsObject, THIS WILL NOT MATCH RESPONSE, NAMES ARE DIFF
            //MAYBE DUMP THIS? AND JUST RELY ON SEEDER? WE CAN STILL TEST ACHIEVEMENTS BUT ILL HAVE TO LOOK INTO IT, NOT 100% SURE WHY I HAD THE ALL ACHIEVEMENTS OBJECT, I THINK IT WAS TO EASILY TEST GRAMMAR WITHOUT DOING SURVEYS
            //TODO add more dev mode config things for testing
            setAchievements(response.data.data);
            // setAchievements(generateAllAchievementsObject());
            setRefreshTimeInSeconds(refershTimeInSeconds * 2);
        })
    }

    useEffect(() => {
        loadAchievements();
    }, [challengeId]);

    useEffect(() => {
        setRefreshTimeInSeconds(5)
        return () => {
            clearTimeout(timer.current);
        }
    }, [])

    useEffect(() => {
        if (isVisible) {
            setRefreshTimeInSeconds(5);
        } else {
            setRefreshTimeInSeconds(maxNumberOfSecondsBeforeCheckingMessages);
        }
    }, [isVisible])

    useEffect(() => {
        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
            loadAchievements()
        }, refershTimeInSeconds * 1000);
    }, [refershTimeInSeconds]);

    return (
        achievements &&
        <div className="achievements-container margin-top-large margin-bottom-med">

            <h2 className="">Achievements</h2>
            <ul className="margin-top-med scrolling-box" onScroll={() => {
                if (!hasScrolled) {
                    ReactGA.event({
                        category: 'Achievements',
                        action: 'Scroll',
                        label: 'Scroll on Achievements'
                    });
                    setHasScrolled(true);
                }
            }}>
                {achievements.length == 0 &&
                    <li>There are no achievements at this time.</li>
                }
                {achievements.map(achievement => {
                    return <NewAchievement userId={userId} teamId={teamId} achievement={achievement}/>
                })}
            </ul>


        </div>

    );

}

export default Achievements;