import React, {useEffect, useState, useCallback, useContext} from 'react';
import {Link} from "react-router-dom";

import Header from "../components/Header";
import Announcement from "../components/Announcement";
import Footer from "../components/Footer";

import mountains from '../assets/images/mountains.svg';
import clock from '../assets/images/clock.svg';
import stars from '../assets/images/stars.svg';
import charlies from '../assets/images/charlies.svg';
import {UserConsumer, UserContext} from "../model/User";
import Request from "../Requests";
import {render} from "react-dom";
import {useHistory} from "react-router-dom";
import moment from "moment";
import Helmet from "react-helmet";
import ReactGA from "react-ga";
import Share from "../components/Share";


function Welcome() {
    const user = useContext(UserContext).user;
    const updateUser = useContext(UserContext).updateUser;
    const [teamMembers, setTeamMembers] = useState(0);
    const [team, setTeam] = useState(0);
    const [challenge, setChallenge] = useState(null);
    const [shouldRender, setShouldRender] = useState(false);

    let history = useHistory();
    const getUserTeam = () => {
        Request.getUserCurrentTeam().then((team_response) => {
            if (team_response.data.length >= 1) {
                Request.getTeamMembers(team_response.data[0].id).then((member_response) => {
                    console.log(member_response.data)
                    setTeamMembers(member_response.data);
                    setTeam(team_response.data[0]);
                })
            }
        });
        setShouldRender(true);
    };
    const getCurrentChallenge = useCallback(() => {
        Request.getActiveChallenge().then((event_response) => {
            if (event_response.data === null) {
                history.push("/holding");
                return;
            }
            readUserRegistration(event_response.data, (userIsRegistered) => {
                if (!event_response.data) {
                    history.push("/open");
                    return;
                }
                if (!userIsRegistered) {
                    history.push("/holding");
                    return
                }
                if (readUserParticipation(event_response.data)) {
                    history.push("/progress");
                    return;
                }
                setChallenge(event_response.data);
                getUserTeam();
            });
        }).catch(() => {
            history.push("/");
        });

    }, [history]);

    const readUserParticipation = (challenge) => {
        let hasParticipated = false;
        user.events.forEach((event) => {
            if (event.event_id === challenge.id) {
                hasParticipated = true;
            }
        });
        return hasParticipated;
    };

    const readUserRegistration = (activeChallenge, callback) => {
        let isRegistered = false;
        Request.getRegistrations(user.user_info.id).then((response) => {
            response.data.registrations.forEach((event) => {
                if (activeChallenge.id === event.id) {
                    isRegistered = true
                }
            });
            callback(isRegistered);
        });
    };


    useEffect(() => {
        getCurrentChallenge();
    }, [getCurrentChallenge]);
    useEffect(() => {
        ReactGA.pageview('/welcome',null, 'Welcome');
    },[]);

    const renderTeamMembers = () => {
        if (teamMembers) {
            return (<div>
                <h2 className="dates">You are on</h2>
                {/*TODO this phrasing could get weird if people choose/edit their team names. Discuss with Comm*/}
                <h2 className="red-text margin-bottom-small">{team.name}</h2>
                <p className="left_column margin-bottom-med">You are not alone! You’ve been assigned to a
                    randomly
                    selected
                    cross-departmental team. Collaboration is the key to success! Use your team’s progress for
                    daily
                    motivation and keep each other accountable. </p>
                <ul className="half_width_containers flex-row wrap margin-bottom-med">
                    {teamMembers.length !== 0 && teamMembers.map(teammate =>
                        <li className="margin-bottom-med" key={teammate.id}>
                            <h2 className="dates">{teammate.first_name} {teammate.last_name}</h2>
                            <h3 className="margin-top-small">{teammate.department}</h3>
                        </li>
                    )}
                </ul>
                <Link to={'/progress'} className="button">Get Started Now!</Link>
            </div>)
        } else {
            return (<div><p className="dates red-text">You are not currently assigned to a team.</p>
            <p>Please <a className="underlined" href="mailto:klmccauliff@bsu.edu?subject=Ball%20State%20University%20Writing%20Challenge" target="_blank">contact an administrator</a>.</p>
            </div>)
        }
    };
    if(shouldRender){
        return (
            challenge &&
            <UserConsumer>
                {({user}) => (
                    user !== null
                    &&
                    <div>
                        <Header/>
                        <div className="main_wrapper margin-bottom-med">
                            <Helmet>
                                <title>BSU Writing Challenge | Welcome</title>
                            </Helmet>
                            <Announcement/>

                            <div className="left_column">
                                <h1 className="red-text margin-bottom-med">Welcome to the {challenge.name} Writing
                                    Challenge, {user.user_info.first_name}</h1>
                                <p className="margin-bottom-med">Ready to get started? Read through the following challenge
                                    information to learn about the timer, the <span
                                        className="red-text bold">Charlies System</span>, and to meet your teammates!</p>
                            </div>

                            <ul className="half_width_containers column-mobile flex-row wrap">
                                {/*<li className="margin-bottom-large mobile_hide">*/}
                                {/*    <img src={mountains} alt=""/>*/}
                                {/*</li>*/}
                                {/*<li className="margin-bottom-large">*/}
                                {/*    <h2>Achievements</h2>*/}
                                {/*    <p className="margin-top-small">You will earn achievements as you progress through the*/}
                                {/*        challenge.*/}
                                {/*        Whenever someone in the challenge earns an achievement, you’ll see it in your feed.*/}
                                {/*        Get*/}
                                {/*        started*/}
                                {/*        writing and see what you can unlock!</p>*/}
                                {/*</li>*/}
                                {/*<li className="margin-bottom-large desktop_hide flex-row flex_align_right">*/}
                                {/*    <img src={mountains} alt=""/>*/}
                                {/*</li>*/}
                                <li className="margin-bottom-large desktop_hide timer-button-center">
                                    <img src={clock} alt=""/>
                                </li>
                                <li className="margin-bottom-large mobile_hide timer-button-center">
                                    <img src={clock} alt=""/>
                                </li>
                                <li className="align_right margin-bottom-large">
                                    <h2>Timer</h2>
                                    <p className="margin-top-small">You will use the timer to record your writing times each
                                        day. Start the timer to clock in your writing! Your writing sessions can be as short
                                        or as long as you like. There’s no limit to the number of writing sessions per day, so
                                        get into it! Keep in mind that the timer only records how <span
                                            className="bold">long</span> you write, not how <span
                                            className="bold">much</span>. You will need to report your word count at the end
                                        of each session.
                                    </p>
                                </li>
                                {/*TODO this doesn't use "stars" any more…*/}
                                <li className="margin-bottom-large mobile_hide timer-button-center">
                                    <img src={charlies} alt=""/>
                                </li>
                                <li className="margin-bottom-large">
                                    <h2>Charlies</h2>
                                    <p className="margin-top-small">Think of the <span
                                        className="red-text bold">Charlies System</span> as a daily check-in. You earn one for each
                                        day of the challenge that you write for at least 30 minutes. To complete the
                                        challenge, try to write each weekday! You can also earn bonus <span
                                            className="red-text bold">Charlies</span> on the weekend if you want to go above
                                        and beyond!</p>
                                </li>
                                <li className="margin-bottom-large flex-row flex_align_right desktop_hide timer-button-center">
                                    <img src={charlies} alt=""/>
                                </li>
                            </ul>
                            {renderTeamMembers()}
                            {challenge && <Share largeMargin={true} challenge={challenge} type={'participating'} />}
                        </div>
                        <Footer/>
                    </div>
                )}
            </UserConsumer>
        )
    }else {
        return null;
    }

}

export default Welcome;
