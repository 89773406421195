import React, {useEffect, useState} from 'react';
import {Link, useHistory} from "react-router-dom";

import Share from "../components/Share";

import {getChallengeDateRange} from "../model/Challenge";
import ReactGA from 'react-ga';
import Request from "../Requests";
import pencil_icon_large from '../assets/images/pencil_icon_large.svg';
import Announcement from "../components/Announcement";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Helmet from "react-helmet";

function RegistrationOpen() {

    const [challenge, setChallenge] = useState(null);
    const history = useHistory();

    useEffect(() => {
        Request.getOpenRegistrationChallenge().then((response) => {
            const event = response.data;
            if (!event) {
                history.push("/");
            } else {
                setChallenge(event);
            }
        })
    }, []);
    useEffect(() => {
        ReactGA.pageview('/open',null, 'Registration Open');
    },[]);
    return (
        challenge &&
        <div>
            <Header/>
            <div className="main_wrapper">
                <Helmet>
                    <title>BSU Writing Challenge | Upcoming Challenge</title>
                </Helmet>
                <Announcement/>
                <div className="flex-row">
                    <div className="left_column">
                        <h1 className="red-text">Faculty Writing Challenge</h1>
                        <p className="margin-bottom-small">The {challenge.name} Writing Challenge is a 14-day,
                            campus-wide writing
                            activity
                            at Ball State University that provides the opportunity for faculty to dedicate time to
                            writing. In this challenge,
                            participants are asked to write daily (Monday–Friday) for at least 30 minutes.</p>

                        <p className="margin-bottom-med">Participants are divided into randomly assigned teams from
                            individuals across campus immediately after the registration period ends. These teams
                            provide a
                            sense of community for the 14-day Writing Challenge to help participants reach their goals
                            and
                            make progress, which unlocks achievements!</p>

                        {/*TODO this h2 originally had a line break in the middle of it for formatting purposes;*/}
                        {/*Talk with Josh about whether it's necessary*/}
                        <div>

                            <h2 className="black-text">{challenge.name}<br/>Writing Challenge</h2>
                            <span
                                className="dates block black-text margin-bottom-large margin-top-small">{getChallengeDateRange(challenge)}</span>

                            <Link className="button" to={'/register'}>Join this Challenge!</Link>
                        </div>


                    </div>
                    <div className="main-pencil-container desktop_hide">
                        <img src={pencil_icon_large} alt=""/>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default RegistrationOpen;
