import React from 'react';
import moment from "moment"

import cardinal_full from "../assets/images/cardinal_full.svg";
import cardinal_empty_red from "../assets/images/cardinal_empty_red.svg";
import cardinal_empty from "../assets/images/cardinal_empty.svg";

function ChallengeProgress({progress, size}) {

    const sizeClass = size === 'large' ? 'current_progress' : 'results_container_small';

    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];


    const getProgressImageForDate = (progressItem) => {
        const date = moment(progressItem.date + ' 00:00').toDate();
        if (progressItem.completed) {
            return <img src={cardinal_full} alt={"writing complete for " + days[date.getDay()]}/>
        }
        const src = (date.getDay() === 6 || date.getDay() === 0) ? cardinal_empty : cardinal_empty_red;
        return <img src={src} alt={"writing complete for " + days[date.getDay()]}/>
    };

    if (!progress) {
        progress = [{date: "2020-02-03"}] //Set first date to a monday if no other dates
    }

    while (progress.length < 14) {
        const lastDate = moment(progress[progress.length - 1].date + ' 00:00')
        progress.push({date: lastDate.add(1,"day").format("YYYY-MM-DD")});
    }

    return (
        <div className={'margin-top-med ' + sizeClass}>
            <ul className={"week_results charlie-flex-row margin-top-small"}>
                {progress.slice(0, 7).map(progressItem => <li
                    key={"progress-item-" + progressItem.date}>{getProgressImageForDate(progressItem)}</li>)}
            </ul>
            <ul className={"week_results charlie-flex-row margin-top-small"}>
                {progress.slice(7, 14).map(progressItem => <li
                    key={"progress-item-" + progressItem.date}>{getProgressImageForDate(progressItem)}</li>)}
            </ul>
            <ul className={"week_results charlie-flex-row margin-top-small"}>
                <li>M</li>
                <li>T</li>
                <li>W</li>
                <li>R</li>
                <li>F</li>
                <li>S</li>
                <li>S</li>
            </ul>
        </div>
    );
}

export default ChallengeProgress;
