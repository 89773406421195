import React, {useEffect, useState} from 'react';
import Requests from "../Requests.js";

function Announcement() {
	//TODO double check that this shows up correctly on every page

	//TODO implement display of the announcement

	let [announcement, setAnnouncement] = useState(null);

	const setAnnouncementState = () => {
		Requests.getAnnouncement().then((data) => {
			setAnnouncement(data.data.value);
		})
	};

	useEffect(() => {
		setAnnouncementState();
	}, []);



	if( !announcement ){
		return null;
	}


	return (
		//return statement that if there is nothing in the database OptionController.php getAnnouncement()
		//stringify to output the object that is annoucement, but it's empty despite console logging fine.

		<div className="notification_banner margin-bottom-med">
			<h2 className="white-text">{announcement}</h2>

		</div>
	);
}

export default Announcement;
