import React, {useState, useEffect, useMemo, useRef, useCallback} from "react";
import ChallengeProgress from "./ChallengeProgress";
import Request from "../Requests";
import Submit from '../assets/images/message_submit.svg';
import moment from "moment";
import ReactGA from "react-ga";

const maxNumberOfSecondsBeforeCheckingMessages = 60*60

const SingleMessage = ({user,message})=>{
    // let readDiff = useMemo(()=>{
    //     let now = moment()
    //     let readAt = moment(message.read_at)
    //     let days = now.diff(readAt,"days");
    //     let hours = now.diff(readAt,"hours");
    //     let minutes = now.diff(readAt,"minutes")
    //     if(days>=1){
    //         return Math.floor(days) +" days ago";
    //     }else if(hours >=1){
    //         return Math.floor(hours) +" hours ago";
    //     }else if(minutes >= 1){
    //         return Math.floor(minutes) + " minutes ago";
    //     }else{
    //         return "now"
    //     }
    // },[message]);
    // let sentDiff = useMemo(()=>{
    //     let now = moment()
    //     let sendAt = moment(message.message_datetime)
    //     let days = now.diff(sendAt,"days");
    //     let hours = now.diff(sendAt,"hours");
    //     let minutes = now.diff(sendAt,"minutes")
    //     if(days>=1){
    //         return Math.floor(days) +" days ago";
    //     }else if(hours >=1){
    //         return Math.floor(hours)+" hours ago";
    //     }else if(minutes >= 1){
    //         return Math.floor(minutes)+ " minutes ago";
    //     }else{
    //         return "now"
    //     }
    // },[message]);
    return(
        <li key={message.id} className="message">

            <ul className="message-columns">
                <li>
                    <span className="name bold">{message.sender_first_name}: </span>
                </li>
                <li>
                    <span className="message-text">{message.message}</span>
                </li>
            </ul>
        </li>
    )
}

const Messages = ({user,challengeId, teamId, teammates,isVisible})=>{
    let [messages,setMessages] = useState(null);
    let [currentSelectedTeamMember, setCurrentSelectedTeamMemeber] = useState(null);
    let [currentMessages, setCurrentMessages] = useState(null);
    let [messageText, setMessageText] = useState("");
    let [clickedMember, setClickedMember] = useState([]);
    let [hasScrolled, setHasScrolled] = useState(false);
    let [messageTimerSeconds, setMessageTimerSeconds] = useState(1);

    const messageBox = useRef(null);
    const messageTimer = useRef(null);
    const previousMessages = useRef(null);


    let getMessages = ()=>{
        return Request.getMessages(challengeId).then(response=>{
            previousMessages.current = messages;
            setMessages(response.data)
        })
    };

    const getTotalNumberOfMessages=(messages)=>{
        let messageLength =0
        messageLength+=messages.group.old.length
        messageLength+=messages.group.new.length
        Object.keys(messages.direct_messages).forEach(key=>{
            messageLength+=messages.direct_messages[key].old.length;
            messageLength+=messages.direct_messages[key].new.length;
        })
        return messageLength;
    }

    useEffect(()=>{
        if(messages != null && previousMessages.current != null){
            let previousMessagesTotal = getTotalNumberOfMessages(previousMessages.current);
            let currentMessageTotal = getTotalNumberOfMessages(messages)
            if(previousMessagesTotal != currentMessageTotal){
                setMessageTimerSeconds(4);
                messageTimer.current = setTimeout(()=>{getMessages()},messageTimerSeconds*4000)
            }else{
                let newTimerLength = messageTimerSeconds*2;
                newTimerLength = newTimerLength < maxNumberOfSecondsBeforeCheckingMessages ? newTimerLength : maxNumberOfSecondsBeforeCheckingMessages;
                setMessageTimerSeconds(newTimerLength);
            }
        }else{
            setMessageTimerSeconds(messageTimerSeconds => messageTimerSeconds+1);

        }
    },[messages])

    let readGroupMessages = useCallback(()=>{
        Request.readMessages(null);
    })



    useEffect(()=>{
        getMessages();
        messageTimer.current = setTimeout(()=>{getMessages()},messageTimerSeconds*1000);
        return ()=>{
            clearTimeout(messageTimer.current)
        }
    },[])

    useEffect(()=>{
        if(currentSelectedTeamMember==null)return;
        ReactGA.event({
            category: 'Chat Box',
            action: 'Click',
            label: 'Teammate name is clicked'
        });
        let clickedMemberCopy = clickedMember.slice();
        clickedMemberCopy.push(currentSelectedTeamMember.id);
        setClickedMember(clickedMemberCopy);
        Request.readMessages(currentSelectedTeamMember.id);
    },[currentSelectedTeamMember]);

    useEffect(()=>{
        if(messageBox.current==null)return;
        messageBox.current.scrollTop=messageBox.current.scrollHeight;
    },[currentMessages])
    

    useEffect(()=>{
        if(messages==null)return;
        if(currentSelectedTeamMember==null){
            setCurrentMessages(messages.group)
            return;
        }
        let currentMessages = messages.direct_messages[currentSelectedTeamMember.id];
        currentMessages = currentMessages == null ? [] : currentMessages;
        setCurrentMessages(currentMessages)

    },[currentSelectedTeamMember, messages])

    useEffect(()=>{
        clearTimeout(messageTimer.current);
        messageTimer.current = setTimeout(()=>{
            getMessages()
        },messageTimerSeconds*1000);
    },[messageTimerSeconds])

    useEffect(()=>{
        if(isVisible){
            setMessageTimerSeconds(3);
        }else{
            setMessageTimerSeconds(maxNumberOfSecondsBeforeCheckingMessages);
        }
    },[isVisible])


    const submitMessage = (e)=>{
        e.preventDefault();
        ReactGA.event({
            category: 'Chat Box',
            action: 'Click',
            label: 'Send Message'
        });
        let receiverId = currentSelectedTeamMember != null ? currentSelectedTeamMember.id : null;

        setMessageText("");
        Request.createMessage(teamId,receiverId,messageText).then(response=>{
            setClickedMember([]);
            setMessageTimerSeconds(.1);

        })
    };

    const updateMessage = (e) => {
        if (e.target.value.length === 1) {
            ReactGA.event({
                category: 'Chat Box',
                action: 'Type',
                label: 'First Character Only'
            });
        }
        setMessageText(e.target.value);
    };
    
    if(messages==null || currentMessages ==null) return null;
    return(
        <section onClick={()=>{readGroupMessages()}} className="team-chat">
            <ul className="teammembers chat-left">
                {teammates.map((member)=>{
                    return(
                        <li key={member.id} className={currentSelectedTeamMember==member? "active" :""} onClick={()=>setCurrentSelectedTeamMemeber(member)}>
                            {member.first_name.substr(0,1)}. {member.last_name}
                            {
                                Object.keys(messages.direct_messages).filter((teamMemberId)=>teamMemberId == member.id).map(teamMemberId=>{
                                    if(clickedMember.indexOf(member.id)!=-1) return null;
                                    return(
                                        <div key={member.id} className={messages.direct_messages[teamMemberId].new.length == 0 ? "badge hidden" : "badge"  }>{messages.direct_messages[teamMemberId].new.length >9 ? "9+" : messages.direct_messages[teamMemberId].new.length}</div>
                                    )
                                })
                            }
                        </li>
                    );
                })}
                <li className={currentSelectedTeamMember==null? "active" :""} onClick={()=>setCurrentSelectedTeamMemeber(null)}>All</li>
            </ul>
            <div className="chat-right">
                {
                    currentSelectedTeamMember &&
                    <div className="member-info">
                        <div className="member-text">
                            <div className="dates name">{currentSelectedTeamMember.first_name} {currentSelectedTeamMember.last_name}</div>
                            <div>{currentSelectedTeamMember.department}</div>
                            <p className="italic small-type margin-top-small">"{currentSelectedTeamMember.goal}"</p>
                        </div>
                      
                        <ChallengeProgress progress={currentSelectedTeamMember.event_data.daily_submission_results} size="small"/>
                    
                    </div>
                }

                <ul ref={messageBox} className="chat-area margin-top-med" onScroll={() => {
                    if (!hasScrolled) {
                        ReactGA.event({
                            category: 'Chat Box',
                            action: 'Scroll',
                            label: 'Scroll on Chat Box'
                        });
                        setHasScrolled(true);
                    }
                }}>
                    {currentMessages.old && currentMessages.old.map(message=>
                        <SingleMessage user={user} key={message.id} message={message}/>
                    )}
                    {currentMessages.new && currentMessages.new.length >0 &&
                        <li className="new-line"><div><span>new</span></div></li>
                    }

                    {currentMessages.new && currentMessages.new.map(message=>
                        <SingleMessage user={user} key={message.id} message={message}/>
                    )}
                </ul>
                <form onSubmit={(e)=>{submitMessage(e)}} className="chat-submission margin-top-med">
                    <input required placeholder="Say Something!" value={messageText} onChange={(e)=>updateMessage(e)} type="text"/>
                    <button><img src={Submit} alt="submit" /></button>
                </form>
            </div>

        </section>
    );
}



export default Messages;