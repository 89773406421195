import React from "react";

function Animation(props,ref) {

    let classes = ['animation'];

    if (props.isTimerCentered !== true) {
        classes.push('animation-left');
    }

    if (props.isCountingDown === true) {
        classes.push('animating');
    }


    return (
        <div ref={ref}  className={classes.join(' ')}>
            <svg  version="1.1" id="Layer_1" x="0px" y="0px"
                 viewBox="0 0 652.38 652.38" style={{'enableBackground': 'new 0 0 652.38 652.38'}}>
                <path id="hour" style={{'fill': '#54585A'}} d="M326.19,86.25c-4.22,0-7.64-3.42-7.64-7.64V7.64c0-4.22,3.42-7.64,7.64-7.64
	s7.64,3.42,7.64,7.64v70.97C333.83,82.83,330.41,86.25,326.19,86.25z"/>
                <path id="minute" style={{'fill': '#CBCDCD'}} d="M408.63,14.74c-1.66,0-3.17,1.1-3.62,2.78l-18.37,68.55c-0.54,2,0.65,4.06,2.65,4.59
	c0.33,0.09,0.65,0.13,0.97,0.13c1.66,0,3.17-1.1,3.62-2.78l18.37-68.55c0.54-2-0.65-4.06-2.65-4.59
	C409.28,14.79,408.96,14.74,408.63,14.74"/>
                <path id="hour_1_" style={{'fill': '#54585A'}} d="M449.97,119.42c-1.3,0-2.61-0.33-3.81-1.03c-3.65-2.11-4.91-6.78-2.8-10.44l35.48-61.46
	c2.11-3.66,6.78-4.91,10.44-2.8c3.65,2.11,4.91,6.78,2.8,10.44L456.6,115.6C455.18,118.05,452.61,119.42,449.97,119.42z"/>
                <path id="minute_11_" style={{'fill': '#CBCDCD'}} d="M551.44,97.19c-0.96,0-1.92,0.37-2.65,1.1l-50.18,50.18c-1.46,1.46-1.46,3.84,0,5.3
	c0.73,0.73,1.69,1.1,2.65,1.1c0.96,0,1.92-0.37,2.65-1.1l50.18-50.18c1.46-1.46,1.46-3.84,0-5.3
	C553.36,97.56,552.4,97.19,551.44,97.19"/>
                <path id="hour_2_" style={{'fill': '#54585A'}} d="M540.61,210.04c-2.64,0-5.21-1.37-6.62-3.82c-2.11-3.65-0.86-8.33,2.8-10.44
	l61.46-35.48c3.66-2.11,8.33-0.86,10.44,2.8c2.11,3.65,0.86,8.33-2.8,10.44l-61.46,35.48C543.22,209.71,541.9,210.04,540.61,210.04z
	"/>
                <path id="minute_10_" style={{'fill': '#CBCDCD'}} d="M633.89,239.99c-0.32,0-0.65,0.04-0.97,0.13l-68.55,18.37
	c-2,0.54-3.19,2.59-2.65,4.59c0.45,1.68,1.96,2.78,3.62,2.78c0.32,0,0.65-0.04,0.97-0.13l68.55-18.37c2-0.54,3.19-2.59,2.65-4.59
	C637.06,241.1,635.54,239.99,633.89,239.99"/>
                <path id="hour_3_" style={{'fill': '#54585A'}} d="M644.74,333.83h-70.97c-4.22,0-7.64-3.42-7.64-7.64c0-4.22,3.42-7.64,7.64-7.64h70.97
	c4.22,0,7.64,3.42,7.64,7.64C652.38,330.41,648.96,333.83,644.74,333.83z"/>
                <path id="minute_9_" style={{'fill': '#CBCDCD'}} d="M565.33,386.52c-1.66,0-3.17,1.1-3.62,2.78c-0.54,2,0.65,4.06,2.65,4.59l68.55,18.37
	c0.33,0.09,0.65,0.13,0.97,0.13c1.66,0,3.17-1.1,3.62-2.78c0.54-2-0.65-4.06-2.65-4.59l-68.55-18.37
	C565.98,386.56,565.65,386.52,565.33,386.52"/>
                <path id="hour_4_" style={{'fill': '#54585A'}} d="M602.05,493.11c-1.3,0-2.61-0.33-3.81-1.03l-61.46-35.48
	c-3.65-2.11-4.91-6.78-2.8-10.44c2.11-3.66,6.78-4.91,10.44-2.8l61.46,35.48c3.65,2.11,4.91,6.78,2.8,10.44
	C607.26,491.74,604.69,493.11,602.05,493.11z"/>
                <path id="minute_8_" style={{'fill': '#CBCDCD'}} d="M501.26,497.51c-0.96,0-1.92,0.37-2.65,1.1c-1.46,1.46-1.46,3.84,0,5.3l50.18,50.18
	c0.73,0.73,1.69,1.1,2.65,1.1c0.96,0,1.92-0.37,2.65-1.1c1.46-1.46,1.46-3.84,0-5.3l-50.18-50.18
	C503.17,497.87,502.22,497.51,501.26,497.51"/>
                <path id="hour_5_" style={{'fill': '#54585A'}} d="M485.47,609.7c-2.64,0-5.21-1.37-6.62-3.82l-35.48-61.46
	c-2.11-3.65-0.86-8.33,2.8-10.44c3.66-2.11,8.33-0.86,10.44,2.8l35.48,61.46c2.11,3.65,0.86,8.33-2.8,10.44
	C488.08,609.37,486.77,609.7,485.47,609.7z"/>
                <path id="minute_7_" style={{'fill': '#CBCDCD'}} d="M390.27,561.58c-0.32,0-0.65,0.04-0.97,0.13c-2,0.54-3.19,2.59-2.65,4.59l18.37,68.55
	c0.45,1.68,1.96,2.78,3.62,2.78c0.32,0,0.65-0.04,0.97-0.13c2-0.54,3.19-2.59,2.65-4.59l-18.37-68.55
	C393.44,562.69,391.93,561.58,390.27,561.58"/>
                <path id="hour_6_" style={{'fill': '#54585A'}} d="M326.19,652.38c-4.22,0-7.64-3.42-7.64-7.64v-70.97c0-4.22,3.42-7.64,7.64-7.64
	s7.64,3.42,7.64,7.64v70.97C333.83,648.96,330.41,652.38,326.19,652.38z"/>
                <path id="minute_6_" style={{'fill': '#CBCDCD'}} d="M262.11,561.58c-1.66,0-3.17,1.1-3.62,2.78l-18.37,68.55c-0.54,2,0.65,4.06,2.65,4.59
	c0.33,0.09,0.65,0.13,0.97,0.13c1.66,0,3.17-1.1,3.62-2.78l18.37-68.55c0.54-2-0.65-4.06-2.65-4.59
	C262.76,561.62,262.43,561.58,262.11,561.58"/>
                <path id="hour_7_" style={{'fill': '#54585A'}} d="M166.91,609.7c-1.3,0-2.61-0.33-3.81-1.03c-3.65-2.11-4.91-6.78-2.8-10.44l35.48-61.46
	c2.11-3.66,6.78-4.91,10.44-2.8c3.65,2.11,4.91,6.78,2.8,10.44l-35.48,61.46C172.12,608.33,169.55,609.7,166.91,609.7z"/>
                <path id="minute_5_" style={{'fill': '#CBCDCD'}} d="M151.12,497.5c-0.96,0-1.92,0.37-2.65,1.1l-50.18,50.18c-1.46,1.46-1.46,3.84,0,5.3
	c0.73,0.73,1.69,1.1,2.65,1.1s1.92-0.37,2.65-1.1l50.18-50.18c1.46-1.46,1.46-3.84,0-5.3C153.04,497.87,152.08,497.5,151.12,497.5"
                />
                <path id="hour_8_" style={{'fill': '#54585A'}} d="M50.33,493.11c-2.64,0-5.21-1.37-6.62-3.82c-2.11-3.65-0.86-8.33,2.8-10.44l61.46-35.48
	c3.66-2.11,8.33-0.86,10.44,2.8c2.11,3.65,0.86,8.33-2.8,10.44l-61.46,35.48C52.94,492.78,51.62,493.11,50.33,493.11z"/>
                <path id="minute_4_" style={{'fill': '#CBCDCD'}} d="M87.05,386.52c-0.32,0-0.65,0.04-0.97,0.13l-68.55,18.37c-2,0.54-3.19,2.59-2.65,4.59
	c0.45,1.68,1.96,2.78,3.62,2.78c0.32,0,0.65-0.04,0.97-0.13l68.55-18.37c2-0.54,3.19-2.59,2.65-4.59
	C90.22,387.62,88.7,386.52,87.05,386.52"/>
                <path id="hour_9_" style={{'fill': '#54585A'}} d="M78.61,333.83H7.64c-4.22,0-7.64-3.42-7.64-7.64c0-4.22,3.42-7.64,7.64-7.64h70.97
	c4.22,0,7.64,3.42,7.64,7.64C86.25,330.41,82.83,333.83,78.61,333.83z"/>
                <path id="minute_3_" style={{'fill': '#CBCDCD'}} d="M18.49,239.99c-1.66,0-3.17,1.1-3.62,2.78c-0.54,2,0.65,4.06,2.65,4.59l68.55,18.37
	c0.33,0.09,0.65,0.13,0.97,0.13c1.66,0,3.17-1.1,3.62-2.78c0.54-2-0.65-4.06-2.65-4.59l-68.55-18.37
	C19.14,240.03,18.81,239.99,18.49,239.99"/>
                <path id="hour_10_" style={{'fill': '#54585A'}} d="M111.77,210.04c-1.3,0-2.61-0.33-3.81-1.03L46.5,173.53
	c-3.65-2.11-4.91-6.78-2.8-10.44c2.11-3.66,6.78-4.91,10.44-2.8l61.46,35.48c3.65,2.11,4.91,6.78,2.8,10.44
	C116.98,208.67,114.41,210.04,111.77,210.04z"/>
                <path id="minute_2_" style={{'fill': '#CBCDCD'}} d="M100.94,97.19c-0.96,0-1.92,0.37-2.65,1.1c-1.46,1.46-1.46,3.84,0,5.3l50.18,50.18
	c0.73,0.73,1.69,1.1,2.65,1.1c0.96,0,1.92-0.37,2.65-1.1c1.46-1.46,1.46-3.84,0-5.3l-50.18-50.18
	C102.86,97.56,101.9,97.19,100.94,97.19"/>
                <path id="hour_11_" style={{'fill': '#54585A'}} d="M202.41,119.42c-2.64,0-5.21-1.37-6.62-3.82L160.3,54.14
	c-2.11-3.65-0.86-8.33,2.8-10.44c3.66-2.11,8.33-0.86,10.44,2.8l35.48,61.46c2.11,3.65,0.86,8.33-2.8,10.44
	C205.02,119.09,203.7,119.42,202.41,119.42z"/>
                <path id="minute_1_" style={{'fill': '#CBCDCD'}} d="M243.75,14.74c-0.32,0-0.65,0.04-0.97,0.13c-2,0.54-3.19,2.59-2.65,4.59l18.37,68.55
	c0.45,1.68,1.96,2.78,3.62,2.78c0.32,0,0.65-0.04,0.97-0.13c2-0.54,3.19-2.59,2.65-4.59l-18.37-68.55
	C246.92,15.85,245.4,14.74,243.75,14.74"/>
            </svg>
        </div>
    );

}

export default React.forwardRef(Animation);