//formatting needs to be different if the challenge ends in a different month than it started…
// e.g. "March 1st–15th" vs "March 28th – April 11th"
import moment from "moment";

export const getChallengeDateRange = (challenge) => {
	const startDate = moment(challenge.start_date);
	const endDate = moment(challenge.end_date);

	const pieces = [];

	pieces.push(startDate.format('MMMM'));
	pieces.push(' ');
	pieces.push(startDate.format('Do'));

	if( startDate.format('M') !== endDate.format('M') ){
		pieces.push('–');
		pieces.push(endDate.format('MMMM'));
		pieces.push(' ');
		pieces.push(endDate.format('Do'));
	} else {
		pieces.push('–');
		pieces.push(endDate.format('Do'));
	}

	return pieces.join('');
};
export const getChallengeRegistrationDateRange = (challenge) => {
	const startDate = moment(challenge.registration_open_date);
	const endDate = moment(challenge.registration_close_date);

	const pieces = [];

	pieces.push(startDate.format('MMMM'));
	pieces.push(' ');
	pieces.push(startDate.format('Do'));

	if( startDate.format('M') !== endDate.format('M') ){
		pieces.push('–');
		pieces.push(endDate.format('MMMM'));
		pieces.push(' ');
		pieces.push(endDate.format('Do'));
	} else {
		pieces.push('–');
		pieces.push(endDate.format('Do'));
	}

	return pieces.join('');
};
