import React, {useCallback, useContext, useEffect, useState} from 'react';
import Helmet from 'react-helmet';
import Header from "../components/Header";
import Announcement from "../components/Announcement";
import ChallengeProgress from "../components/ChallengeProgress";
import Share from "../components/Share";
import Footer from "../components/Footer";
import cardinal_full from "../assets/images/cardinal_full.svg";

import {getChallengeDateRange, getChallengeRegistrationDateRange} from "../model/Challenge";

import down_arrow from '../assets/images/down_arrow.svg';
import {Link, useHistory} from "react-router-dom";
import Request from "../Requests";
import moment from "moment";
import {UserContext} from "../model/User";
import Redirect from "react-router-dom/es/Redirect";
import {API_URL} from "../config/config";
import Requests from "../Requests";
import ReactGA from "react-ga";


function Holding() {

    const user = useContext(UserContext).user;
    const [challenge, setChallenge] = useState(null);
    const [pastChallenges, setPastChallenges] = useState(null);
    const [futureChallenges, setFutureChallenges] = useState(null);
    const [liveState, setLiveState] = useState(null);
    const [isRegistered, setRegistered] = useState(false);
    const [shouldRender, setShouldRender] = useState(false);
    let history = useHistory();

    const getRegistrationOpenChallenge = () => {
        Request.getOpenRegistrationChallenge().then((response) => {
            getActiveChallenge((activeChallenge, challengeIsActive) => {
                getUpcomingChallenges((upcomingChallenges) => {
                    setFutureChallenges(upcomingChallenges);
                    if (response.data !== null) {
                        setChallenge(response.data);
                        setLiveState("registration");
                    } else if (challengeIsActive) {
                        setChallenge(activeChallenge);
                        setLiveState("active")
                    } else if (upcomingChallenges[0] && moment().isBetween(moment(upcomingChallenges[0].registration_close_date), moment(upcomingChallenges[0].start_date))) {
                        setChallenge(upcomingChallenges[0]);
                        setLiveState("middle-period")
                    } else if (upcomingChallenges[0] && moment(upcomingChallenges[0].registration_open_date).format() > moment().format()) {
                        setChallenge(upcomingChallenges[0]);
                        setLiveState("upcoming-challenge")
                    } else {
                        setLiveState("no-upcoming-challenge")
                    }

                })
            })
        });
    };
    const getPastChallenges = () => {
        Request.getPastChallenges().then((response) => {
            setPastChallenges(response.data)
        })
    };
    const getActiveChallenge = (callback) => {

        let challengeActive = false;
        Request.getActiveChallenge().then((response) => {
            if (response.data !== null) {
                challengeActive = true;
            }
            callback(response.data, challengeActive);
        });
    };
    const getUpcomingChallenges = (callback) => {
        Request.getUpcomingChallenges().then((response) => {
            callback(response.data);
        });
    };
    useEffect(() => {
        if (challenge !== null && liveState !== null) {
            Request.getRegistrations(user.user_info.id).then((response) => {
                response.data.registrations.forEach((event) => {
                    if (challenge.id === event.id) {
                        setRegistered(true)
                    }
                });
                setShouldRender(true);
            });
        } else if (liveState !== null) {
            setShouldRender(true);
        }
    }, [challenge, liveState]);
    useEffect(() => {
        ReactGA.pageview('/holding',null, 'Holding');
    },[]);
    const renderChallengeStatus = () => {
        if (!challenge && liveState === "no-upcoming-challenge") {
            return (
                <div><p className="dates red-text">No upcoming challenges!</p></div>
            )
        } else if (!isRegistered && liveState === "registration") {
            let daysLeft = 0;
            if(challenge){
                daysLeft = moment.duration(moment(challenge.registration_close_date).diff(moment()));
                daysLeft = daysLeft.asDays()+1;
            }
            return (
                <div>
                    <p className="dates red-text margin-bottom-small">Registration is now open!</p>
                    <h2 className="margin-bottom-med">{challenge.name} Writing Challenge</h2>
                    <p className="dates red-text">
                        {Math.ceil(daysLeft) > 1 ? (Math.ceil(daysLeft) +" days left to register!") : "Registration closes today!"}
                    </p>
                </div>
            )
        } else if (isRegistered && (liveState === "registration" || liveState === "middle-period")) {
            let daysLeft = moment.duration(moment(challenge.start_date).diff(moment()));
            daysLeft = daysLeft.asDays();
            if (daysLeft < 1) {
                return (
                    <div>
                        <div>
                            <p className="dates red-text">Congratulations, you are now registered for
                                the <strong className="black-text">{challenge.name} Writing Challenge!</strong></p>
                        </div>
                        <br/><br/>
                        <p className="dates">{challenge.name} Writing Challenge starts tomorrow!</p>
                        <div className="margin-top-med"><Share challenge={challenge} type={'registered'} /></div>
                    </div>
                )
            } else {
                return (
                    <div>
                        <div>
                            <p className="dates red-text margin-bottom-med">Congratulations, you are now registered for
                                the <strong className="black-text">{challenge.name} Writing Challenge!</strong></p>
                        </div>

                        <p className="dates black-text">
                            <strong className="black-text margin-bottom-med">{Math.ceil(daysLeft)} day{Math.ceil(daysLeft) > 1 ? "s" : ""}</strong> until the
                            challenge!</p>
                        <p className="dates black-text">Check back
                            on <strong className="black-text">{moment(challenge.start_date).format('LL')}</strong>.</p>
                        <div className="margin-top-med"><Share challenge={challenge} type={'registered'} /></div>
                    </div>
                )
            }
        } else if (!isRegistered && (liveState === "middle-period" || liveState === "active")) {
            return (
                <div>
                    <p className="dates red-text">Oh no! You missed this challenge.<br/>There’s
                        always next time!</p>
                </div>
            )
        }
        // else if (isRegistered && liveState === "middle-period") {
        //     let daysLeft = moment.duration(moment(challenge.start_date).diff(moment()));
        //     daysLeft = daysLeft.asDays();
        //     if (daysLeft < 1) {
        //         return (
        //             <div>
        //                 <p className="dates red-text">{challenge.name} Writing Challenge starts tomorrow!</p>
        //             </div>
        //         )
        //     } else {
        //         return (
        //             <div>
        //                 <p className="dates red-text">
        //                     <strong>{Math.trunc(daysLeft)} day{Math.trunc(daysLeft) > 1 ? "s" : ""}</strong> until
        //                     challenge!</p><br/><br/><br/>
        //             </div>
        //         )
        //     }
        //
        // }
        else if (isRegistered && liveState === "active") {
            return (
                <Redirect to={'/welcome'}/>
            )
        } else if (!isRegistered && liveState === "active") {
            return (
                <div>
                    {getFutureChallenge()}
                    <br/><br/>
                    <p className="dates red-text">Oh no! You missed the registration period for this challenge! </p>
                    <p className="dates red-text">There's always next time!</p>
                </div>
            )
        } else if (liveState === "upcoming-challenge") {
            return (
                <div>
                    <p className="dates red-text">Mark your calendars!</p>
                    <h2 className="margin-bottom-small">{challenge.name} Writing Challenge is on its way!</h2>
                </div>
            )
        }
    };
    const renderRegisterButton = () => {
        if (!isRegistered && liveState === "registration") {
            return (
                <div>
                    <Link onClick={e => ReactGA.event({
                        category: 'Join This Challenge',
                        action: 'Click',
                        label: challenge.name
                    })} className="button bottom-margin-large" to={'/register'}>Join this Challenge!</Link>

                </div>

            )
        }
    };

    const getFutureChallenge = () => {
        if (!isRegistered && liveState === "active" && futureChallenges[0]) {
            return (
                <div>
                    <p className="dates red-text">Upcoming Challenges</p>
                    <h2 className="black-text margin-top-small"> {futureChallenges[0].name}<br/>Writing Challenge</h2>
                    <p className="margin-top-med small_dates">Registration Period</p>
                    <p className="red-text bold">{moment(futureChallenges[0].registration_open_date).format('LL')} – {moment(futureChallenges[0].registration_close_date).format('LL')}</p>
                    <p className="margin-top-small small_dates">Challange Dates</p>
                    <p className="red-text bold">{moment(futureChallenges[0].start_date).format('LL')} – {moment(futureChallenges[0].end_date).format("LL")}</p>
                </div>
            );
        }
    };
    useEffect(() => {
        getRegistrationOpenChallenge();
        getPastChallenges();
    }, []);

    let [userID, setUserID] = useState(null);
    Requests.getCurrentUser().then(data => {
        setUserID(data.data.id);
    });
    const getUpcomingChallenge = () => {
        if(!challenge){
            return null;
        }
        if (liveState === "upcoming-challenge" || (!isRegistered && liveState === "registration") ) {
            return (
                <div className="right_column right_align">
                    <p className="margin-top-med small_dates">Registration Period</p>
                    <p className="red-text bold">{getChallengeRegistrationDateRange(challenge)}</p>

                    <p className="small_dates margin-top-small">Challenge Dates</p>
                    <p className="red-text bold">{getChallengeDateRange(challenge)}</p>

                </div>
            )
        }
    };

    const registrationInfo = () => {


        return (
            <div>
                <div className="flex-row margin-bottom-med align_bottom mobile_center">
                    <div className="left_column">
                        {renderChallengeStatus()}
                    </div>
                    {getUpcomingChallenge()}

                </div>
                <div className="mobile_center">
                    {renderRegisterButton()}
                </div>
            </div>
        )
    };
    if (shouldRender) {
        return (
            <div>
                <Header/>
                <div className="main_wrapper">
                    <Helmet>
                        <title>BSU Writing Challenge | Upcoming Challenge</title>
                    </Helmet>
                    <Announcement/>
                    <div className="left_column">
                        {/*TODO this h2 originally had a line break in the middle of it for formatting purposes;*/}
                        {/*Talk with Josh about whether it's necessary*/}
                        <h1 className="red-text">Ball State Faculty Writing Challenge</h1>
                        {challenge &&
                        <p className="margin-bottom-small">The {challenge.name} Writing Challenge is a 14-day,
                            campus-wide
                            writing activity at Ball State University
                            that provides the opportunity for faculty to dedicate time to writing. In this challenge,
                            participants are asked to write daily (Monday–Friday) for at least 30 minutes.
                        </p>
                        }
                        {!challenge && liveState === "no-upcoming-challenge" &&
                        <p className="margin-bottom-small">This site hosts 14-day, campus-wide writing activities at
                            Ball
                            State University
                            that provide the opportunity for faculty to dedicate time to writing. In these challenges,
                            participants are asked to write daily (Monday–Friday) for at least 30 minutes.
                        </p>
                        }


                        <p className="margin-bottom-med">Participants are divided into randomly assigned teams from
                            individuals across campus. These teams provide a
                            sense of community for the 14-day Writing Challenge to help participants reach their goals
                            and
                            make progress. {liveState==='no-upcoming-challenge' && "Check back for upcoming challenges."}</p>


                    </div>
                    {registrationInfo()}
                </div>
                <div className="main_wrapper margin-top-med">

                    <ul className="challenge_column_wrapper flex-row wrap margin-bottom-med column-mobile">
                        {user.events.map(challenge =>
                            <li key={"holding-challenge-" + challenge.event_id}
                                className="challenge_column margin-bottom-med">
                                <h2>{challenge.event_name}<br/>Writing Challenge</h2>
                                <span
                                    className="dates mobile_form_margin_bottom">{getChallengeDateRange(challenge)}</span>

                                <ChallengeProgress progress={challenge.user_results.daily_submission_results}/>

                                <div className="flex-row mobile_med_margin_bottom  margin-bottom-small">
                                    <a target="_blank" onClick={e => ReactGA.event({
                                        category: 'Download Data',
                                        action: 'Click',
                                        label: challenge.name
                                    })}
                                       href={API_URL + "users/" + userID + "/event_survey/" + challenge.event_id + "/" + localStorage.getItem("challengeSessionKey")}
                                       download className="flex-row">
                                        <p className="margin_right margin-top-small">Download survey data</p>
                                        <img className="download_arrow" src={down_arrow} alt=""/>
                                    </a>
                                </div>

                                <div className="flex-row mobile_med_margin_bottom  margin-bottom-small">
                                    <a target="_blank"
                                       href={API_URL + "events/" + challenge.event_id + "/overall/" + localStorage.getItem("challengeSessionKey")}
                                       download className="flex-row">
                                        <p className="margin_right margin-top-small">Download writing data</p>
                                        <img className="download_arrow" src={down_arrow} alt=""/>
                                    </a>
                                </div>

                                <div className="flex-row mobile_med_margin_bottom  margin-bottom-small">
                                    <a target="_blank" href={"/" + challenge.event_id + "/certificate"}
                                       className="flex-row">
                                        <p className="margin_right margin-top-small">View Certificate</p>
                                        <img className="download_arrow" src={cardinal_full} alt=""/>
                                    </a>
                                </div>

                            </li>
                        )}
                    </ul>
                    {!isRegistered && (liveState !== 'registration' && liveState !== 'middle-period') && user && user.events && user.events.length !== 0 && <div className="margin-bottom-med">
                        <Share largeMargin={true} challenge={user.events[user.events.length - 1]} type={'participated'} />
                    </div>}
                </div>
                <Footer/>
            </div>
        );
    } else {
        return null;

    }

}

export default Holding;
