import moment from "moment";
import black_number_of_session from "../assets/images/AchievementsIcons/black_number_of_sessions.svg";
import black_time_of_day from "../assets/images/AchievementsIcons/black_time_of_day.svg";
import black_time_written from "../assets/images/AchievementsIcons/black_time_written.svg";
import black_word_count from "../assets/images/AchievementsIcons/black_word_count.svg";
import black_writing_hours from "../assets/images/AchievementsIcons/black_writing_hours.svg";
import black_cheerleader from "../assets/images/AchievementsIcons/black_cheerleader.svg";
import red_number_of_session from "../assets/images/AchievementsIcons/red_number_of_sessions.svg";
import red_time_of_day from "../assets/images/AchievementsIcons/red_time_of_day.svg";
import red_time_written from "../assets/images/AchievementsIcons/red_time_written.svg";
import red_word_count from "../assets/images/AchievementsIcons/red_word_count.svg";
import red_writing_hours from "../assets/images/AchievementsIcons/red_writing_hours.svg";
import red_cheerleader from "../assets/images/AchievementsIcons/red_cheerleader.svg";
import React, {memo, useMemo} from "react";


export const achievements = {
    'TOD11P2A': {
        message: '{name} {verb} {variation}',
        ownedVerb: 'are',
        unownedVerb: 'is',
        variations: ['burning the midnight oil!', 'up late!']
    },
    'TOD4A9A': {
        message: '{variation} {name} {verb} already written today!',
        ownedVerb: 'have',
        unownedVerb: 'has',
        variations: ['The early bird gets the worm!', 'Up and at \'em!', 'Sieze the day!']
    },
    'TTD4': {
        message: '{name} wrote for 4 hours today! {variation}',
        variations: ['That\'s longer than the runtime of "Gone with the Wind."', 'That\'s even longer than the time it takes for a coat of paint to dry!', 'That\'s about the same time an average adult takes to build a LEGO Death Star!']
    },
    'TTD6': {
        message: '{name} is {variation} They wrote over 6 hours today!',
        variations: ['on top of it!', 'crushing it!', 'killing it!']
    },
    'TTT15': {
        message: '{variation} {name} has completed 15 hours of writing overall!',
        variations: ['{name} makes it look easy!', 'Kudos to {name}!', '{name} sure has the magic touch!']
    },
    'TTT20': {
        message: '{name} has written over 20 hours total! That\'s as long as a flight to Australia.'
    },
    'TWD2000': {
        message: '{name} {variation}; they wrote more than 2000 words today!',
        variations: ['is on fire', 'is a writing warrior']
    },
    'TWD3000': {
        message: '{name} {variation} They wrote more than 3000 words today!',
        variations: ['can\'t be stopped!', 'has found ink-spiration!']
    },
    'TWT20000': {
        message: 'Woohoo! {name} {variation}',
        variations: ['wrote more than 20,000 words in this challenge! What an accomplishment!', 'has written their way to greatness and wrote more than 20,000 words in this challenge!']
    },
    'TWT30000': {
        message: 'Wow! {name} wrote over 30,000 words. That\'s more than {variation}',
        variations: ['"Of Mice and Men" by John Steinbeck.', '"Animal Farm" by George Orwell.']
    },
    'UCS2': {
        message: '{variation} {name} wrote TWICE today!',
        variations: ['Double duty!', 'Double trouble!', 'Double whammy!', 'Two good to be true!']
    },
    'UCS4': {
        message: '{name} wrote four times today! {variation}',
        variations: ['That\'s as many times as FDR was elected president!', 'That\'s longer than the runtime of Cleopatra!']
    },
    'UTD1_59_40': {
        message: '{name} {verb} written for 1:59:40 today! That\'s as long as it took world record breaker, Eliud Kipchoge to run a marathon!',
        ownedVerb: 'have',
        unownedVerb: 'has'
    },
    'UTD4': {
        message: '{name} {verb} completed quite a journey! {name} {variation}',
        ownedVerb: 'have',
        unownedVerb: 'has',
        variations: ['wrote longer than the runtime of Lord of the Rings: Fellowship of the Ring.', 'wrote longer than the runtime of Napolean!']
    },
    'UTS2': {
        message: '{name} {verb} {variation} {name} completed 2 hours of writing in one session. That\'s longer than the runtime of the Wizard of Oz!',
        ownedVerb: 'are',
        unownedVerb: 'is',
        variations: ['off to see the Wizard!', 'has got a brain!']
    },
    'UTS3': {
        message: '{name} just won\'t quit! {name} {verb} completed more than 3 hours of writing in one session.',
        ownedVerb: 'have',
        unownedVerb: 'has'
    },
    'UTT10': {
        message: '{name} {verb} written over 10 hours total! {variation}',
        ownedVerb: 'have',
        unownedVerb: 'has',
        variations: ['That\'s longer than a flight to London!', 'That\'s how long it takes to learn the basics of an instrument!']
    },
    'UTT15': {
        message: '{name} {verb} written 15 hours total! That\'s as long as it would take you to drive to Daytona Beach, Florida. Think of the warm weather :)',
        ownedVerb: 'have',
        unownedVerb: 'has'
    },
    'UWD1000': {
        message: '{name}\'s {variation} {name} wrote 1000 words today!',
        variations: ['keyboard is on fire!', 'keyboard is on a roll!']
    },
    'UWD1700': {
        message: '{name} wrote more words in one day than {variation}',
        variations: ['MLK\'s "I Have a Dream" speech!', 'in the Declaration of Independence!']
    },
    'UWD2500': {
        message: '{name} {variation} {name} wrote 2500 words today!',
        variations: ['had a very productive day!', 'had a vigorous day of writing!', 'had a fruitful day of writing!']
    },
    'UWT8000': {
        message: '{name} wrote 8,000 words total! That\'s longer than Charlotte Perkins Gilman\'s "The Yellow Wallpaper."'
    },
    'UWT10000': {
        message: '{name} {verb} {variation} {name} wrote 10,000 words total!',
        ownedVerb: 'are',
        unownedVerb: 'is',
        variations: ['a pro!', 'a master!', 'really getting the hang of this!', 'crushing it!']
    },
    'UCHAT': {
        message: '{name} {verb} spreading the message! Sent a message to their team!',
        ownedVerb: 'are',
        unownedVerb: 'is'
    },
    'TTD1': {
        message: '{name} has written for 1 hour today! Wanna make it 2 hours?'
    },
    'TTD2': {
        message: 'Sweet! 2 hours on the clock for {name}! Keep it up!'
    },
    'TTD3': {
        message: '{name} has typed for 3 hours today! Teamwork certainly makes the dream work!'
    },
    'TTD5': {
        message: 'Woah! {name} has been writing for five hours today, keep up the good work!'
    },
    'TTD7': {
        message: 'Excellent! {name} has written for seven hours today!'
    },
    'TTD8': {
        message: 'Awesome! {name} has written for eight hours today! Don\'t hurt yourself...'
    },
    'TTD9': {
        message: 'For nine hours, {name} has been typing! The other teams don\'t stand a chance!'
    },
    'TTD10': {
        message: 'OMG! {name} has been typing for TEN hours today! Great job!'
    },
    'TTD11': {
        message: 'I can\'t believe it! {name} has typed for 11 hours today! That\'s amazing!'
    },
    'TTD12': {
        message: 'Twelve hours? {name} has been typing for 12 hours! Maybe it\'s time for a screen break...'
    },
    'TTD12_35': {
        message: '{name} has written for 12 hours and 35 minutes! That\'s the same amount as someone completing the Ironman Marathon!'
    },
    'TTD13': {
        message: '{name} has been typing for thirteen hours! {name} is crazy, awesome!'
    },
    'UTS4': {
        message: '{name} typed for 4 hours! Make sure to stretch those fingers!'
    },
    'UTS5': {
        message: 'Great! {name} fingers are unstoppable! {name} wrote for 5 hours today already!'
    },
    'UTS6': {
        message: 'Outstanding! {name} {verb} been typing for 6 hours! {verb} {name} had human interaction today?',
        ownedVerb: 'have',
        unownedVerb: 'has'
    },
    'UTS7': {
        message: 'Why was 6 afraid of 7? Because {name} {verb} been typing for 7 hours! Wait, that\'s not how it goes...',
        ownedVerb: 'have',
        unownedVerb: 'has'
    },
    'UTS8': {
        message: 'Well done! {name} {verb} 8 hours under {pronoun} belt, maybe they should consider paying {name} for typing so long...',
        ownedVerb: 'have',
        unownedVerb: 'has'
    },
    'UTS10': {
        message: 'Ten hours! Wow, {name} {verb} unstoppable! Today {name} typed for ten hours!',
        ownedVerb: 'are',
        unownedVerb: 'is'
    },
    'UTS11': {
        message: 'Holy wow! {name} {verb} been typing on here for 11 hours, that is crazy, awesome!',
        ownedVerb: 'have',
        unownedVerb: 'has'
    },
    'UTS1_20': {
        message: '{name} typed for 1 hour and 20 minutes in one session! That\'s how long it would take to swim to the bottom of the Pacific Ocean, but don\'t try it (please)!'
    },
    'UTS1_30': {
        message: '{name} {verb} been typing for 1 hour and 30 minutes, that\'s how long it takes to listen to one of your favorite albums!',
        ownedVerb: 'have',
        unownedVerb: 'has'
    },
    'UTT5': {
        message: '{name} typed for five hours already, pretty impressive.'
    },
    'UTT6': {
        message: 'Amazing! {name} typed for six hours! Keep it up!'
    },
    'UTT7': {
        message: '{name} typed for seven hours! {name} {verb} a pat on the back!',
        ownedVerb: 'deserve',
        unownedVerb: 'deserves'
    },
    'UTT8': {
        message: 'Incredible! {name} typed for eight hours, that\'s the same amount as a full school day!'
    },
    'UTT9': {
        message: 'Wow, {name} {verb} on top of it! {name} typed nine hours!',
        ownedVerb: 'are',
        unownedVerb: 'is'
    },
    'TTT9_30': {
        message: 'Buongiorno! {name} has been typing for 9 hours and 30 minutes, that\'s how long it takes to fly from Indiana to Italy!',
    },
    'TTT72': {
        message: 'To the moon and back! {name} has been typing for 3 days, that\'s how long it takes to get to the moon from Earth in a rocket ship!',
    },
    'TTT25': {
        message: 'Holy cow! {name} wrote 25 hours so far! {name} is MOO-ving along nicely! (See what we did there).',
    },
    'TTT30': {
        message: 'Geesh! {name} wrote 30 hours this week! Are their fingers okay?'
    },
    'TWD1000': {
        message: 'Wow! {name} wrote 1000 words today! Has {name} considered starting a novel?',
    },
    'UWT6570': {
        message: 'Wow! {name} {verb} written the same amount as Junie B. Jones and the Stupid Smelly Bus, that\'s 6,570 words!',
        ownedVerb: 'have',
        unownedVerb: 'has'
    },
    'TWT30644': {
        message: 'Those who invest in chocolate put their money behind bars... anyways {name} wrote more than Charlie and the Chocolate Factory! That\'s 30,644 words!'
    },
    'UWT16750': {
        message: 'Impressive, {name} wrote 16,750 words! That\'s the same amount as the Magic Tree House The Knight at Dawn book!'
    },
    'TWT36000': {
        message: 'Cool! {name} has written the same amount as The Lion, the Witch, and the Wardrobe, that\'s 36,000 words!'
    }


};


function NewAchievement({userId, teamId, achievement}) {
    const currentAchievement = achievements[achievement.code];

    const isRedIcon = useMemo(() => achievement.team_id === teamId, [achievement.team_id, teamId]);

    const iconSVG = useMemo(() => {
        const startingCode = achievement.code.substring(0, 3);

        switch (startingCode) {
            case 'TOD':
                return isRedIcon ? red_time_of_day : black_time_of_day;
            case 'TTD':
            case 'UTD':
            case 'UTS':
                return isRedIcon ? red_time_written : black_time_written;
            case 'TTT':
            case 'UTT':
                return isRedIcon ? red_writing_hours : black_writing_hours;
            case 'TWD':
            case 'TWT':
            case 'UWD':
            case 'UWT':
                return isRedIcon ? red_word_count : black_word_count;
            case 'UCS':
                return isRedIcon ? red_number_of_session : black_number_of_session;
            case 'UCH':
                return isRedIcon ? red_cheerleader : black_cheerleader;
            default:
                return '';
        }
    }, [achievement.code, isRedIcon]);

    const claimer = useMemo(() => {
        if (achievement.user_id === userId) {
            return {name: 'You', verb: currentAchievement.ownedVerb};
        } else if (achievement.user_id) {
            return {name: achievement.user_name, verb: currentAchievement.unownedVerb};
        } else if (achievement.team_id === teamId) {
            return {name: 'Your team', verb: currentAchievement.ownedVerb};
        } else {
            return {name: `The ${achievement.team_name} team`, verb: currentAchievement.unownedVerb};
        }
    }, [achievement.user_id, achievement.team_id, achievement.user_name, achievement.team_name, userId, teamId, currentAchievement]);


    const renderNameSpan = (name) => (
        <span className="red-text bold">{name}</span>
    );

    const renderVariation = (variation, claimerName) => {
        if (variation.includes('{name}')) {
            console.log(variation)
            return variation.split('{name}').map((segment, index, array) => (
                <span key={index}>
                    {segment}
                    {index !== array.length - 1 && renderNameSpan(claimerName)}
                </span>
            ));
        }
        return variation;
    };

    const achievementMessage = useMemo(() => {
        const parts = currentAchievement.message.split(/(\{name}|\{verb}|\{variation})/g);

        return parts.map((part, i) => {
            switch (part) {
                case '{name}':
                    return renderNameSpan(claimer.name);
                case '{verb}':
                    return claimer.verb;
                case '{variation}':
                    // always get the same variation for the same user_id or team_id attached to the achievement
                    // if user_id is null use the team_id
                    const variationIndex = parseInt(achievement.user_id || achievement.team_id, 10) % currentAchievement.variations.length;
                    const chosenVariation = currentAchievement.variations[variationIndex];
                    return renderVariation(chosenVariation, claimer.name);
                default:
                    return part;
            }
        });


    }, [currentAchievement, claimer, achievement.user_id, achievement.team_id]);



    return (
        <li className="flex-row achievment-row">
            <img src={iconSVG} alt="achievement icon" className="icon-size"/>
            <div>
                <time className="achievement-date">{moment(achievement.created_at).format("MM.DD.YY")}</time>
                <p>{achievementMessage}</p>
            </div>
        </li>
    )
}

export default memo(NewAchievement)
