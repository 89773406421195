import React, {useCallback, useContext, useEffect, useMemo, useState, useRef} from 'react';
import moment from "moment";
import Helmet from "react-helmet";
import {Redirect, useHistory, Prompt} from "react-router-dom";
//import NumberFormat from "react-number-format";
import {UserConsumer, UserContext} from "../model/User";
import Request from "../Requests";
import Animation from "../components/Animation";
import {getChallengeStatus} from "./CurrentChallenge";
import upArrow from "../assets/images/uparrow.svg";
import downArrow from "../assets/images/downarrow.svg";
import {BASE_URL} from "../config/config";
import Footer from '../components/Footer';
import ReactGA from "react-ga";

import completeSound from '../assets/complete.mp3';

const prependZero = (number) => {
    if (number < 10) {
        number = "0" + number;
    }
    return number;
};

//TODO what is the homescreen prompt? Find out and add it after word count submission has been completed
//TODO load in the user data. Ask Drew or Clayton about UseContext
//TODO what does connect sections with logic mean? Ask Riley or Bryon

const Timer = () => {
    let history = useHistory();

    //timeLeft is set in milliseconds, if you hate this blame Drew
    const [challenge, setChallenge] = useState(null);
    const [timeLeft, setTimeLeft] = useState(1);
    const [finishTime, setFinishTime] = useState(moment);
    const [isCountingDown, setIsCountingDown] = useState(false);
    const [minutes, setMinutes] = useState(30);
    const [hours, setHours] = useState(0);
    const [timerStarted, setTimerStarted] = useState(false);
    const [paused, setPaused] = useState(false);
    const [isFinished, setIsFinished] = useState(false);
    const [wordCount, setWordCount] = useState(0);
    const [sessionWordCount, setSessionWordCount] = useState("");
    const [currentDayTimeWritten, setCurrentDayTimeWritten] = useState(null);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const user = useContext(UserContext).user;
    const [isCancellationVisible, setIsCancellationVisible] = useState(false);
    const [registrationState, setRegistrationState] = useState({loaded: false, isRegistered: false});
    const [timerChange, setTimerChange] = useState(true);
    const [firstTypedWordCount, setFirstTypedWordCount] = useState(true);
    const [writeAgainButton,setWriteAgainButton] = useState(null);
    const animation = useRef(null);

    const countDown = () => {
        if (isCountingDown) {
            let start = moment();
            let end = finishTime.clone();
            let duration = moment.duration(end.diff(start));
            setTimeLeft(duration.asMilliseconds());
        }
    };

    const cancelButton = () => {
        ReactGA.event({
            category: 'Timer',
            action: 'Click',
            label: 'Cancel'
        });
        history.push("/progress");

    };

    const homeButton = () => {
        ReactGA.event({
            category: 'Timer',
            action: 'Click',
            label: 'Go Home'
        });
        history.push("/progress");
    };

    const writeAgain = () => {
        ReactGA.event({
            category: 'Timer',
            action: 'Click',
            label: 'Write Again'
        });
        window.location.reload();
    };

    const addMinutes = (e) => {
        setMinutes(parseInt(e.target.value));
        // if (minutes == 59 && e.target.value > minutes - 1) {
        //     setMinutes(0);
        //     setHours(hours + 1);
        // } else if (minutes == 0 && e.target.value < minutes + 1 && hours != 0) {
        //     setMinutes(59);
        //     setHours(hours - 1);
        // }
        // else if (minutes == 0 && hours == 0 && e.target.value != 1) {
        //     setMinutes(0);
        //     setHours(0);
        // }
    };

    const addHours = (e) => {
        setHours(parseInt(e.target.value));
    };

    useEffect(() => {
        let timeToAdd = (minutes * 60 * 1000) + (hours * 60 * 60 * 1000);
        setTimeLeft(timeToAdd);
    }, [minutes, hours]);

    const startTimer = () => {
        ReactGA.event({
            category: 'Timer',
            action: 'Click',
            label: 'Cancel'
        });
        let currentMoment = moment();
        let finishedTime = currentMoment.add(timeLeft, "milliseconds");
        setFinishTime(finishedTime);

        setIsCountingDown(true);
        setTimerStarted(true)
    };

    const endEarly = () => {
        ReactGA.event({
            category: 'Timer',
            action: 'Click',
            label: 'Cancel'
        });
        setPaused(false);
        if (isCancellationVisible === true) {
            setIsFinished(true);
            setTimerStarted(false);
        } else {
            setIsCancellationVisible(true);
        }
        setIsCountingDown(false);

    };

    useEffect(() => {
        if (timeLeft <= 0 && timerStarted) {
            const audio = new Audio(completeSound);
            setTimeout(async ()=>{
                try{
                    await audio.play()
                }catch(e){
                    console.log(e);
                }
            },1);


            setIsFinished(true);
            setTimerStarted(false);
            setIsCountingDown(false);
        }
    }, [timeLeft]);

    useEffect(() => {
        const timer = setInterval(() => {
            countDown();

        }, 100);
        window.onbeforeunload = (event) => {
            if (!isSubmitted) {
                event.preventDefault();
                event.returnType = "Are you sure you want to leave? Your progress is unsaved.";
                return "Are you sure you want to leave? Your progress is unsaved."
            }
        };
        return () => clearInterval(timer);
    });

    useEffect(()=>{
        let handleWindowSizeChange = ()=>{
            console.log(animation.current, writeAgainButton)
            if(animation.current==null || writeAgainButton==null)return;
            if(animation.current.offsetWidth <=800){
                writeAgainButton.style.top = "0px";
                writeAgainButton.style.left = "0px";
                return;
            }
            let {top,left,right,bottom,width,height} = animation.current.querySelector("svg").getBoundingClientRect();
            writeAgainButton.style.top = (top + height/2)+"px";
            writeAgainButton.style.left = (left + width/2)+"px";

            console.log(top, left, height, width);
            console.log(writeAgainButton.getBoundingClientRect());
        }
        handleWindowSizeChange();
        window.removeEventListener("resize",handleWindowSizeChange)
        window.addEventListener("resize", handleWindowSizeChange)
        return ()=>window.removeEventListener("resize",handleWindowSizeChange);
    },[animation,writeAgainButton])

    useEffect(() => {
        ReactGA.pageview('/timer',null, 'Timer');
    },[]);
    const getCurrentChallenge = useCallback(() => {
        return Request.getActiveChallenge().then((event_response) => {
            if (event_response.data === null) {
                history.push("/");
                return null;
            }
            return event_response;
        }).catch(() => {
            history.push("/");
        });

    }, [history]);


    const currentEvent = () => {
        let events = user.events.filter(function (event) {
            return getChallengeStatus(event) === "active";
        });
        if (events.length === 0) {
            return null;
        } else {
            return events[0];
        }
    };

    useEffect(() => {
        getCurrentChallenge().then((response) => {
            if(response==null)return;
            let challengeData = response.data;
            if (challengeData === null) {
                return
            }
            setChallenge(challengeData);
            Request.getRegistrations(user.user_info.id).then((response) => {
                let registered = false;
                response.data.registrations.forEach((event) => {
                    if (challengeData.id === event.id) {
                        registered = true;
                    }
                });
                setRegistrationState({loaded: true, isRegistered: registered})
            });
        });
    }, []);

    useEffect(() => {
        let event = currentEvent();
        if (event === null) {
            getCurrentChallenge().then(event => {
                if(event==null){
                    return;
                }
                event.user_results = {
                    total_words: 0,
                    total_seconds: 0,
                    completed: false,
                    timePerWeek: [0],
                    wordsPerWeek: [0],
                    daily_submission_results: [],
                    surveys: []
                };
                for (let result in event.user_results.daily_submission_results) {
                    if (moment(event.user_results.daily_submission_results[result].date).date() == moment().date()) {
                        setCurrentDayTimeWritten(event.user_results.daily_submission_results[result].daily_time_in_seconds);
                        setWordCount(event.user_results.daily_submission_results[result].daily_words);
                    }
                }
            });

        } else {
            for (let result in event.user_results.daily_submission_results) {
                if (moment(event.user_results.daily_submission_results[result].date).date() == moment().date()) {
                    setCurrentDayTimeWritten(event.user_results.daily_submission_results[result].daily_time_in_seconds);
                    setWordCount(event.user_results.daily_submission_results[result].daily_words);
                }
            }
        }
    }, []);

    const submitWordCount = (passedWordCount) => {
        if (isSubmitted) return;
        if (passedWordCount < 0 || Math.floor(passedWordCount) !== Math.ceil(passedWordCount)) {
            alert("Please enter a valid positive number");
            return;
        }
        setIsSubmitted(true);
        let timeSpentWriting = hours * 60 * 60 * 1000;//get hours
        timeSpentWriting += minutes * 60 * 1000;//get minutes
        timeSpentWriting -= timeLeft; // remove unspent time
        let formattedHours = prependZero(Math.floor(timeSpentWriting / 60 / 60 / 1000));
        let formattedMinutes = prependZero(Math.floor(timeSpentWriting % (60 * 60 * 1000) / 60 / 1000));
        let formattedSeconds = prependZero(Math.floor((timeSpentWriting % (60 * 1000) / 1000)));
        let total_time_written = "" + formattedHours + ":" + formattedMinutes + ":" + formattedSeconds;
        let userData = {
            word_count: passedWordCount,
            total_time_written: total_time_written
        };
        Request.postSurveyData(userData).then((response) => {
            console.log(window.onbeforeunload);
        });
    };

    const handleWordCountEntry = (e) => {
        if(firstTypedWordCount){
            setFirstTypedWordCount(false);
            ReactGA.event({
                category: 'Timer',
                action: 'Type',
                label: 'First Character Only'
            })
        }
        let previousWordCountEntry = sessionWordCount;
        if (!e.target.value.match(/^\d*$/)) {
            setSessionWordCount(previousWordCountEntry);
        } else {
            setSessionWordCount(e.target.value)
        }
    };

    const timerClasses = ['main_wrapper flex-row '];
    if (timerStarted) {
        timerClasses.push('timer-active');
    }

    const onPauseClick = () => {
        ReactGA.event({
            category: 'Timer',
            action: 'Click',
            label: 'Pause'
        });
        setIsCountingDown(false);
        setPaused(true);
    };

    const onContinueClick = () => {
        startTimer();
        setPaused(false);
        setIsCancellationVisible(false);
        ReactGA.event({
            category: 'Timer',
            action: 'Click',
            label: 'Continue'
        })
    };

    const increaseHours = ()=>{
        setHours(hours+1);
        if(hours == 23){
            setHours(0);
        }
    }

    const increaseMinutes = () => {
        if (timerChange) {
            setTimerChange(false);
            ReactGA.event({
                category: 'Timer',
                action: 'Click',
                label: 'Up/Down - first click only'
            })
        }
        setMinutes(minutes + 1);
        if (minutes == 59) {
            setMinutes(0);
            setHours(hours + 1);
        }
    };


    const decreaseHours = ()=>{
        setHours(hours-1)
        if(hours<=0){
            setHours(0);
        }
    }

    const decreaseMinutes = () => {
        if (timerChange) {
            setTimerChange(false);
            ReactGA.event({
                category: 'Timer',
                action: 'Click',
                label: 'Up/Down - first click only'
            })
        }
        setMinutes(minutes - 1);
        if (minutes == 0 && hours != 0) {
            setMinutes(59);
            setHours(hours - 1);
        } else if (minutes == 0 && hours == 0) {
            setMinutes(0);
            setHours(0);
        }
    };

    useEffect(() => {
        if (isSubmitted) {
            setWordCount(parseInt(wordCount) + parseInt(sessionWordCount));
        }
    }, [isSubmitted]);

    let totalDailyTime = useMemo(() => {
        let startingTime = currentDayTimeWritten * 1000;
        let timeSpentWriting = hours * 60 * 60 * 1000;//get hours
        timeSpentWriting += minutes * 60 * 1000;//get minutes
        timeSpentWriting -= timeLeft; // remove unspent time
        return startingTime + timeSpentWriting;
    }, [isFinished, hours, minutes, timeLeft]);

    if (registrationState.loaded && !registrationState.isRegistered) {
        return <Redirect to={"/holding"}/>;
    }

    return (
        registrationState.loaded &&
        <UserConsumer>
            {({user, updateUser}) => (
                user !== null &&
                <div>
                    <Prompt when={!isSubmitted} message={(location)=>"Are you sure you want to leave? Your progress is unsaved."}/>
                    <div className="main_wrapper timer">
                        <Helmet>
                            <title>BSU Writing Challenge | Timer</title>
                        </Helmet>
                        {/*<Announcement />*/}
                        {!isFinished && (
                            <section>
                                <div className={timerClasses.join(' ')}>
                                    <div className="left_timer_column"/>
                                    <div className="flex_align_right right_timer_column timer-center not-finished">
                                        {/*First screen the user should see before they've started the timer*/}
                                        {!timerStarted && <>
                                            {currentDayTimeWritten >= 1 &&
                                            <h2 className="dates margin-bottom-small"> You've written for <span
                                                className="red">{Math.floor((currentDayTimeWritten / 60) / 60)} hour(s) and {Math.floor(currentDayTimeWritten / 60) % 60} minute(s) </span> today
                                            </h2>}
                                            {currentDayTimeWritten < 1 &&
                                            <h2 className="dates margin-bottom-small"> You haven't written yet!</h2>
                                            }
                                            <h2 className="margin-bottom-small">Let's Get Writing</h2>
                                            <p className="margin-bottom-small">Set the timer for however long you’d
                                                like, open up your preferred writing platform, and get writing! When the
                                                timer is up, you’ll be asked to record your word count for the day.
                                                After a minimum of 30 minutes of writing, you will be asked to fill out
                                                a short
                                                survey!</p>
                                            <i>This survey is meant to be a personal reflection tool and will not be
                                                read or saved by anyone. </i>
                                        </>}
                                        {timerStarted && <h2>Writer at work. Keep Going!</h2>}
                                        {!isFinished && (
                                            <section>
                                                {/*conditionally render when timer has been started*/}
                                                {/*IMPORTANT anywhere you see timerStarted &&_____ that is a place that is conditionally rendered for once they've started the timer*/}
                                                {timerStarted &&
                                                // this is the timer itself
                                                <span
                                                    className="timecount">{prependZero(Math.floor(((timeLeft / 1000) / 60) / 60)) + ":" + prependZero(Math.floor(((timeLeft / 1000) / 60) % 60)) + ":" + prependZero(Math.floor((timeLeft / 1000) % 60))}</span>
                                                }
                                            </section>
                                        )}
                                        {isCancellationVisible &&
                                        <h2 className="h2_button_padding">Are you sure you want to stop?</h2>}
                                        {isCancellationVisible && (<button className="button pause pointer"
                                                                           onClick={() => onContinueClick()}>Keep
                                            Writing</button>)}
                                        {isCancellationVisible && !paused && (
                                            <button className="link horizontal_center pointer"
                                                    onClick={() => endEarly()}>No, I'm Done</button>)}


                                        {timerStarted && !isCancellationVisible && !paused && (
                                            <button className="button pause pointer"
                                                    onClick={() => onPauseClick()}>Pause</button>)}
                                        {timerStarted && !isCancellationVisible && paused && (
                                            <button className="button pause pointer"
                                                    onClick={() => onContinueClick()}>Continue</button>)}
                                        {timerStarted && !isCancellationVisible && (
                                            <button className="link horizontal_center pointer"
                                                    onClick={() => endEarly()}>Stop</button>)}
                                        {!timerStarted && (
                                            //This is the timer input div
                                            <div className="flex-column margin-top-small timer-button-center">
                                                <div className="timer-input flex-row_timer">
                                                    <input className="timer-input" type="number" name="hours"
                                                           value={hours}
                                                           min={0} max={24} onChange={addHours}/>
                                                    <p className="timer-input unselectable">h:</p>
                                                    <div className="flex-column justify_center margin-left-small">
                                                        <button className="arrow-buttons" onClick={increaseHours}><img
                                                            src={upArrow} alt="more"/></button>
                                                        <button className="arrow-buttons" onClick={decreaseHours}><img
                                                            src={downArrow} alt="less"/></button>
                                                    </div>
                                                    <input className="timer-input" type="number" name="minutes"
                                                           value={minutes} max={60} onChange={addMinutes}/>
                                                    <p className="timer-input unselectable">m</p>
                                                    <div className="flex-column justify_center margin-left-small">
                                                        <button className="arrow-buttons" onClick={increaseMinutes}><img
                                                            src={upArrow} alt="more"/></button>
                                                        <button className="arrow-buttons" onClick={decreaseMinutes}><img
                                                            src={downArrow} alt="less"/></button>
                                                    </div>
                                                </div>
                                                {!timerStarted &&
                                                <button disabled={hours==0&&minutes==0} className="button margin-bottom-small justify-center pointer"
                                                        onClick={() => startTimer()}>Start</button>}
                                                {/*need to hook up time in h2.dates to populate with the time user has written that day*/}
                                                {/*need to hook up the button below to return user to My Challenge Progress page*/}
                                                <button className="link horizontal_center pointer"
                                                        onClick={cancelButton}>Cancel
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </section>
                        )}

                        {/*TODO if they haven't written for a total of 30 minutes for the current day don't display this section*/}
                        {/*TODO disable google forms survey if they haven't written for 30 minutes that day*/}
                        {/*TODO check against user_id in post_writing_survey_data for total_time_written to see if they've written for 30 minutes*/}
                        {isFinished && (
                            <section className="flex-row_write_again">
                                <div className="write-again-column">
                                    {/*<p className="red-text not-a-timer">00h:00m:00s</p>*/}
                                    
                                </div>

                                <div className="left_timer_column">
                                {isSubmitted &&
                                    <button ref={ref => {console.log("set ref",ref);setWriteAgainButton(ref)}} className="button pointer timer-button" onClick={writeAgain}>WRITE AGAIN</button>}
                                    </div>

                                <form onSubmit={(e) => {
                                    e.preventDefault();
                                    submitWordCount(sessionWordCount)
                                }} className="flex_align_right margin-top-large right_timer_column">
                                    {/*Final screen*/}
                                    {/*IMPORTANT anywhere you see isFinished &&_____ that is a place that is conditionally rendered for once the timer has finished or they've ended early*/}
                                    {totalDailyTime / 60 / 1000 >= 30 &&
                                    <h2 className="black-text margin-bottom-small">You did it! You've written
                                        for {Math.floor(totalDailyTime / 60 / 60 / 1000)} hour(s)
                                        and {Math.floor((totalDailyTime / 60 / 1000) % 60)} minute(s) today!</h2>}
                                    {/*need to hook up time in the h2 above to populate with the time user has written that day post-timer*/}
                                    {totalDailyTime / 60 / 1000 < 30 &&
                                    <h2 className="dates margin-bottom-small">You've written for <span
                                        className="red">{Math.floor(totalDailyTime / 60 / 60 / 1000)} hour(s) and {Math.floor((totalDailyTime / 60 / 1000) % 60)} minute(s) </span> today.
                                        To earn your Charlie for today, you must write for <span
                                            className="red-text">{30 - Math.floor(totalDailyTime / 1000 / 60)} more minute(s).</span>
                                    </h2>}
                                    {isSubmitted ?
                                        <p className={"margin-bottom-small"}>You
                                            submitted <b>{sessionWordCount.toLocaleString()}</b> words this session!</p>
                                        :
                                        <>
                                            <p className="margin-bottom-small">How many words did you write this
                                                session?</p>
                                            <input className="red-box margin-bottom-small" type="text" name="wordCount"
                                                   required
                                                   value={sessionWordCount} placeholder={0} min={0}
                                                   onChange={event => handleWordCountEntry(event)}/>
                                        </>
                                    }

                                    <div className="flex-row justify_between">{!isSubmitted && (<button
                                        className="button-small margin-bottom-med pointer"
                                    >Submit</button>)}
                                        {isSubmitted && (
                                            <a className="button button-small margin-bottom-med pointer"
                                               href={BASE_URL + "progress"}>Return to Home</a>
                                        )}
                                        <p className=" margin-bottom-med">your total today: <span
                                            className="red"> {wordCount.toLocaleString('en-US')} word(s) </span></p>
                                        {/*need to hook up word count in the p above to populate with the total words user has written that day*/}
                                    </div>
                                    {totalDailyTime / 60 / 1000 >= 30 && isSubmitted && (
                                        <iframe
                                            id="formIframe"
                                            src={challenge.survey_url.replace('BSUEMAIL', user.user_info.email)}
                                            width="100%" height="454" frameBorder="0" marginHeight="0"
                                            marginWidth="0"
                                            onFocus={e => ReactGA.event({
                                                category: 'Google Form',
                                                action: 'Click',
                                                label: 'Submit'
                                            })}>Loading…
                                        </iframe>
                                    )}

                                </form>
                            </section>
                        )}
                        <Animation ref={animation} isTimerCentered={timerStarted} isCountingDown={isCountingDown}/>
                    </div>
                    <Footer/>
                </div>
            )}
        </UserConsumer>
    );
};

export default Timer;
