import React from 'react';
import {Link} from "react-router-dom";

import Header from "../components/Header";
import Announcement from "../components/Announcement";
import Footer from "../components/Footer";
import Helmet from "react-helmet";

function Error() {
	return (
		<div>
			<Header/>
		<div className="main_wrapper">
			<Helmet>
				<title>BSU Writing Challenge | Error</title>
			</Helmet>
			<Announcement />
			<h1 className="margin-top-large">
				Hmmm... something seems to have gone wrong. </h1>
			<Link className="red-text block margin-top-large" to={'/'}>Click here to return to the main page.</Link>
		</div>
		<Footer/>
		</div>
	);
}

export default Error;
