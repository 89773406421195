export const DEVELOPMENT_MODE = false;

//whatever you seeded in the db, by default its "DREWHEISS"
export const DEVELOPMENT_MODE_SESSION_KEY = "DREWHEISS"

export const SHIBBOLETH_URL = "https://prod-digitalcorps.servers.bsu.edu/simplesaml/bsu_login.php?redirectURL=https://writing-challenge-api.apsoprojects.org/public/auth/user";
export const API_URL = DEVELOPMENT_MODE ? "https://writing-challenge-api.apsoprojects.org/public/" : "https://writing-challenge-api.apsoprojects.org/public/";
export const BASE_URL = DEVELOPMENT_MODE ? "http://localhost:3000/" : "https://writingchallenge.apsoprojects.org/";

// export const API_BASE_URL = DEVELOPMENT_MODE ?
//     "http://localhost:8888/writing-challenge-api-full/public/"
//     : "";