import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Link, useHistory} from "react-router-dom";

import Header from "../components/Header";
import Announcement from "../components/Announcement";
import ChallengeProgress from "../components/ChallengeProgress";
import Footer from "../components/Footer";
import moment from "moment";
import {UserContext} from "../model/User";
import Helmet from "react-helmet";
import Request from "../Requests";
import pencil_icon_large from "../assets/images/pencil_icon_large.svg";
import Messages from "../components/Messages";
import Goal from "../components/Goal";
import Achievements from "../components/Achievements";
import ReactGA from "react-ga";
import TrackVisibility from 'react-on-screen';
import Share from "../components/Share";


function CurrentChallenge() {

    const user = useContext(UserContext).user;
    const [challenge, setChallenge] = useState(null);
    const [team, setTeam] = useState(null);
    const [redirected, setRedirected] = useState(false);
    const [teamNameEditable, setTeamNameEditable] = useState(false);
    let history = useHistory();

    useEffect(() => {
        redirectIfUserIsNotRegistered().then(() => {
            if (!redirected) {
                setTeamState();
            }
        });
    }, [challenge]);
    useEffect(() => {
        ReactGA.pageview('/progress', null, 'Progress');
    }, []);
    const currentEvent = () => {
        console.log(user)
        let events = user.events.filter(function (event) {
            return getChallengeStatus(event) === "active";
        });
        console.log(events[0])
        if (events.length === 0) {
            return null;
        } else {
            return events[0];
        }
    };

    const currentDayResults = () => {
        let currentDay = null;
        if (currentEvent()) {
            [currentDay] = currentEvent().user_results.daily_submission_results.filter(user_result => {
                let today = new Date();
                return moment(user_result.date).toDate().toDateString() === moment(today.toDateString()).toDate().toDateString();
            });
        }
        return currentDay ? currentDay : {
            completed: false,
            daily_time_in_seconds: 0,
            daily_words: 0,
            isRequired: true,
            submissions: []
        }
    };

    const getCurrentChallenge = useCallback(() => {
        Request.getActiveChallenge().then((event_response) => {
            if (!event_response.data) {
                history.push("/open");
                return;
            }
            setChallenge(event_response.data);
        }).catch(() => {
            history.push("/");
        });

    }, [history]);

    const redirectIfUserIsNotRegistered = () => {
        if (challenge === null) {
            return new Promise(() => {
            })
        }
        return Request.getRegistrations(user.user_info.id).then((response) => {
            let registered = false;
            response.data.registrations.forEach((event) => {
                if (challenge.id === event.id) {
                    registered = true;
                }
            });
            if (!registered) {
                setRedirected(true);
                history.push('/holding')
            }
        });
    };

    const setTeamState = () => {
        if (challenge === null) {
            return;
        }
        Request.getUserCurrentTeam().then(teamResponse => {
            let team = teamResponse.data.filter((team) => {
                return team.event_id === challenge.id;
            });
            if (team.length === 0) {
                setTeam(null);
                return;
            }
            team = team.pop();
            Request.getTeamMembers(team.id).then(membersResponse => {
                team.teammates = membersResponse.data;
                team.edit_name = team.name;
                setTeam(team);
            })
        })
    };

    const saveTeamName = (event) => {
        event.preventDefault();
        if (!team.edit_name.trim()) {
            alert("Your team must have a name");
            return
        }
        Request.updateTeamName(team.edit_name).then(response => {
            setTeam({...team, name: team.edit_name});
            setTeamNameEditable(false);
        })
    };

    const teamWordsTotal = () => {
        if (!(team && team.teammates)) {
            return 0;
        }
        let total = 0;
        team.teammates.forEach(teammate => {
            console.log(teammate.event_data)
            total += teammate.event_data.total_words;
        });
        return total;
    };

    const teamSecondsTotal = () => {
        if (!(team && team.teammates)) {
            return 0;
        }
        let total = 0;
        team.teammates.forEach(teammate => {
            total += teammate.event_data.total_seconds;
        });
        return total;
    };

    useEffect(() => {
        getCurrentChallenge();
    }, [getCurrentChallenge]);

    function renderTeamInfo() {
        if (!team || !team.teammates || team.teammates.length === 0) return null;
        let teammates = team.teammates.filter(teammate => teammate.id != user.user_info.id);
        return (
            <>
                {team && team.teammates && team.teammates.length !== 0 && team.teammates.map(teammate =>
                    teammate.id !== user.user_info.id &&
                    <li key={"challenge-team-" + teammate.id} className="challenge_column margin-bottom-med">

                        <h2 className="dates">{teammate.first_name} {teammate.last_name}</h2>
                        <h3 className="margin-top-small">{teammate.department}</h3>
                        <h3 className="margin-top-small mobile_form_margin_bottom">{teammate.email}</h3>

                        <ChallengeProgress progress={teammate.event_data.daily_submission_results}/>
                    </li>
                )}
                <TrackVisibility partialVisibility>
                    <Messages user={user} challengeId={challenge.id} teamId={team.id} teammates={teammates}/>
                </TrackVisibility>
            </>
        )
    }

    function renderTeamSection() {
        if (team === null) {
            return null
        }
        return (
            <>
                <div className="team_stats margin-bottom-med">
                    {teamNameEditable ?
                        <form className="margin-bottom-med" onSubmit={(event) => saveTeamName(event)}>
                            <input type={"text"}
                                   placeholder="Team Awesome!"
                                   onChange={(e) => {
                                       if (e.target.value.length === 1) {
                                           ReactGA.event({
                                               category: 'Team Name',
                                               action: 'Type',
                                               label: 'First Character Only'
                                           });
                                       }
                                       setTeam({...team, edit_name: e.target.value})
                                   }}
                                   value={team.edit_name}
                            />
                            <button type={"submit"} className={"button-small margin_left_button"}>Save</button>
                            <button type={"reset"} className={"button-small margin_left_button"} onClick={() => {
                                setTeamNameEditable(false);
                                setTeam({...team, edit_name: team.name})
                            }}>Cancel
                            </button>
                        </form>
                        : <div>
                            <h2 className={"red-text"} style={{display: 'inline'}}>{team && team.name}</h2>
                            <label className="edit-text" onClick={(e) => {
                                ReactGA.event({
                                    category: 'Team Name',
                                    action: 'Click',
                                    label: 'Edit Button'
                                });
                                setTeamNameEditable(true);
                            }}><img
                                className={"edit-pencil"} src={pencil_icon_large} alt={"Edit team name"}/> Edit team
                                name</label>
                            <p className="left_column margin-top-small margin-bottom-med block_text">Meet your team!
                                Each day, you can monitor your team members' progress and watch the word count grow as
                                they earn <span className="red-text bold">Charlies</span>!</p>
                        </div>

                    }

                    <ul>
                        <li className="stats_cat margin-top-small">
                            <span className="bold">{teamWordsTotal().toLocaleString()}</span> total words written
                        </li>
                        <li className="stats_cat margin-top-small">
                            <span className="bold">{getTimeStringFromSeconds(teamSecondsTotal())}</span> of writing
                        </li>
                    </ul>
                </div>
                <ul className="challenge_column_wrapper flex-row wrap margin-bottom-med half_width_containers column-mobile ">
                    {renderTeamInfo()}
                    <TrackVisibility partialVisibility>
                        <Achievements challengeId={challenge.id} userId={user.user_info.id} teamId={team.id}/>
                    </TrackVisibility>
                </ul>
            </>
        )
    }

    function getWeekIndex() {
        //this just returns a zero or one?? not the index for the entire week
        let now = moment();
        let endWeekOne = moment(currentEvent().start_date, "YYYY-MM-DD", true).add(7, "days");
        console.log(endWeekOne)
        console.log(currentEvent().user_results);
        return now.isAfter(endWeekOne) ? 1 : 0;
    }

    function getTimeStringFromSeconds(seconds) {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds - (hours * 3600)) / 60);
        if (hours > 0) {
            return hours + " hr " + minutes + " min(s)";
        } else {
            return minutes + " min(s)";
        }
    }

    function renderStatsContainer() {
        return <div className="stats_container_large">
            <h2 className="black-text">This week</h2>
            <ul className="margin-top-small">
                <li><p
                    className="stats_number red-text margin-bottom-small">{currentEvent() ? currentEvent().user_results.wordsPerWeek[getWeekIndex()].toLocaleString() : 0}<span
                    className="stats_cat black-text block_text"> total words written</span></p>
                </li>
                {/* <li><p className="stats_cat margin-bottom-small">words written</p></li> */}
                <li><p
                    className="stats_number red-text margin-bottom-small">{getTimeStringFromSeconds(currentEvent() ? currentEvent().user_results.timePerWeek[getWeekIndex()] : 0)}<span
                    className="stats_cat black-text block_text"> of writing</span></p>
                </li>
                {/* <li><p className="stats_cat margin-bottom-small">of writing</p></li> */}
            </ul>

            <h2 className="black-text">Challenge Total</h2>
            <ul className="margin-top-small">
                <li><p
                    className="stats_number red-text margin-bottom-small">{currentEvent() ? currentEvent().user_results.total_words.toLocaleString() : 0}<span
                    className="stats_cat black-text block_text"> total words written</span></p>
                </li>
                {/* <li><p className="stats_cat margin-bottom-small">words written</p></li> */}
                <li><p
                    className="stats_number red-text margin-bottom-small">{getTimeStringFromSeconds(currentEvent() ? currentEvent().user_results.total_seconds : 0)}<span
                    className="stats_cat black-text block_text"> of writing</span></p>
                </li>
                {/* <li><p className="stats_cat margin-bottom-small">of writing</p></li> */}
            </ul>
        </div>
    }

    return (
        <div>
            <Header/>
            <div className="main_wrapper">
                <Helmet>
                    <title>BSU Writing Challenge</title>
                </Helmet>
                <Announcement/>

                <h1 className="red-text margin-bottom-med left_column">My Challenge Progress</h1>
                <p className="left_column margin-bottom-med block_text">
                    Aim for a minimum of 30 minutes per day to earn your <span className="red-text bold">Charlie</span>.
                    Your Charlie, word count, and time will be updated automatically.
                </p>

                <div className="flex-row margin-bottom-large column-mobile">

                    <div className="left_column flex-column mobile_margin_bottom">

                        <Link to={'/timer'}
                              className="button start_button_ring writing_button_top_margin margin-bottom-large">
                            {currentEvent() === null && (<div>Start Your Timer!</div>)}
                            {currentEvent() !== null && (<div>Write Again!</div>)}
                        </Link>
                        <Goal/>

                        <p className="margin-bottom-small margin-top-med">You have written for <span
                            className="bold">{getTimeStringFromSeconds(currentDayResults().daily_time_in_seconds)}</span> today.
                        </p>

                        <ChallengeProgress
                            progress={currentEvent() && currentEvent().user_results.daily_submission_results}
                            size={'large'}/>

                    </div>

                    {renderStatsContainer()}
                </div>
                <div className="divider margin-bottom-med"/>
                {renderTeamSection()}
                {challenge && <div className="margin-bottom-med"><Share largeMargin={true} challenge={challenge} type={'participating'} /></div>}
            </div>
            <Footer/>
        </div>
    );
}

export default CurrentChallenge;

function formatDate(date) {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

export function getChallengeStatus(challenge) {
    let status;
    let now = new Date();
    now = formatDate(now);
    if (moment(challenge.start_date).isSameOrBefore(moment(now)) && moment(challenge.end_date).isSameOrAfter(now)) {
        status = 'active';
    } else if (moment(challenge.start_date).isAfter(moment())) {
        status = 'upcoming';
    } else {
        status = 'past';
    }
    return status;
}
