import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Switch, Route, useLocation} from 'react-router-dom';
import Request from "../src/Requests";
import Error from "./containers/Error";
import Register from "./containers/Register";
import Welcome from "./containers/Welcome";
import Header from "./components/Header";
import Holding from "./containers/Holding";
import RegistrationOpen from "./containers/RegistrationOpen";
import Timer from "./containers/Timer";
import CurrentChallenge from "./containers/CurrentChallenge";
import "./config/config";
import {BASE_URL, DEVELOPMENT_MODE, SHIBBOLETH_URL} from "./config/config";
import {UserProvider} from "./model/User";
import ScrollToTop from "./components/ScrollToTop";
import Certificate from "./components/Certificate";
import ReactGA from 'react-ga';

function getUrlParameter(name) {
    name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    const results = regex.exec(window.location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

function App() {
    ReactGA.initialize('UA-158883919-1');
    const [user, updateUser]  = useState(null);

    useEffect(() => {
        getCurrentUser();
    }, []);


    let sessionKey = getUrlParameter("challengeSessionKey");
    if (sessionKey !== "") {
        localStorage.setItem("challengeSessionKey", sessionKey);
        window.location = BASE_URL;
        return;
    }

    if(!DEVELOPMENT_MODE) {
        // if there is no session lets go get one
        if (localStorage.getItem("challengeSessionKey") === "" || localStorage.getItem("challengeSessionKey") === null) {
            window.location = SHIBBOLETH_URL;
            return;
        }
    }

    //TODO implement Google Analytics sitewide
    const getCurrentUser = () => {
        Request.getHistory().then((history_response) => {
            console.log(history_response.data);
            updateUser(history_response.data);
        })
    };
    const determineLandingPage = () => {
        return <Holding/>
    };


    return (
        user &&
        <UserProvider value={{
            user: user,
            updateUser: updateUser,
        }}>
            <Router>
                <ScrollToTop/>
                <div className="App">
                    <Switch>
                        <Route exact path="/">{determineLandingPage()}</Route>
                        <Route exact path="/timer"><Timer/></Route>
                        <Route exact path="/register"><Register/></Route>
                        <Route exact path="/welcome"><Welcome/></Route>

                        {/*TODO remove these items after site is tested; they're just for easy page display*/}
                        <Route exact path="/progress"><CurrentChallenge/></Route>
                        <Route exact path="/holding"><Holding/></Route>
                        <Route exact path="/open"><RegistrationOpen/></Route>

                        <Route exact path="/:challengeId/certificate"><Certificate/></Route>

                        <Route><Error/></Route>
                    </Switch>
                </div>
            </Router>
        </UserProvider>

    );
}

export default App;
