import React, {useContext, useEffect, useState} from 'react';
import Request from "../Requests";
import Moment from 'react-moment';
import {UserConsumer, UserContext} from "../../src/model/User"
import Announcement from "../components/Announcement";
import Footer from "../components/Footer";
import {getChallengeDateRange} from '../model/Challenge';
import Header from "../components/Header";
import {useHistory} from "react-router-dom";
import Helmet from "react-helmet";
import ReactGA from "react-ga";


function Register() {

    const user = useContext(UserContext).user;
    const updateUser = useContext(UserContext).updateUser;
    const [registration, setRegistration] = useState({goal: ""});
    const [challenge, updateChallenge] = useState(null);
    const [departments, updateDepartments] = useState([]);
    const [offices, updateOffices] = useState([]);
    const [isRegistering, updateIsRegistering] = useState(false);

    let history = useHistory();

    useEffect(() => {
        setBSUDepartmentsState();
        setBSUOfficesState();
        setChallengeState();
    }, []);

    const setBSUDepartmentsState = () => {
        Request.getBSUDepartments().then((response) => {
            let departments = [];
            response.data.colleges.forEach((college) => {
                college.departments.forEach((department) => {
                    departments.push(department.name);
                })
            });
            updateDepartments(departments);
        });
    };

    const setBSUOfficesState = () => {
        Request.getBSUOffices().then((response) => {
            let offices = [];
            response.data.offices.forEach(office => {
                offices.push(office.name);
            });
            updateOffices(offices);
        })
    };

    const setChallengeState = () => {
        Request.getOpenRegistrationChallenge().then((response) => {
            updateChallenge(response.data);
            if (response.data === null) {
                history.push("/holding");
                return;
            }
            redirectIfUserIsRegistered(response.data)
        }).catch((error) => {
            history.push("/holding");
        })
    };

    const redirectIfUserIsRegistered = (openRegistrationChallenge) => {
        Request.getRegistrations(user.user_info.id).then((response) => {
            response.data.registrations.forEach((event) => {
                if (openRegistrationChallenge.id === event.id) {
                    history.push("/holding")
                }
            });
        });
    };

    const submitRegistration = e => {
        e.preventDefault();
        if (!isRegistering) {
            updateIsRegistering(true);
            Request.updateCurrentUser(user.user_info).then((response) => {
                updateUser(
                    {
                        ...user,
                        user_info: response.data
                    });
                Request.registerUserToChallenge(registration).then(() => {
                    history.push("/holding");
                }).catch(() => alert("Error: Could not register for the event."))
            }).catch(() => alert("Error: Could not update the user."));
        }
    };

    function getUserProp(prop) {
        return user.user_info[prop] ? user.user_info[prop] : "";
    }

    function setUserProp(prop, value) {
        let newUser = {...user};
        newUser.user_info[prop] = value;
        updateUser(newUser);
    }

    function getPhoneNumber() {
        let number = user.user_info.phone_number ? user.user_info.phone_number : "";
        number = number.replace(/\D/g, '');
        if (number.length === 0) {
            number = "";
        } else if (number.length <= 3) {
            number = "(" + number.slice(0, 3);
        } else if (number.length <= 6) {
            number = "(" + number.slice(0, 3) + ") " + number.slice(3, 6)
        } else {
            number = "(" + number.slice(0, 3) + ") " + number.slice(3, 6) + "-" + number.slice(6, 10)
        }
        return number;
    }
    useEffect(() => {
        ReactGA.pageview('/register',null, 'Register');
    },[]);
    function updatePhoneNumber(event) {
        let number = event.target.value;
        number = number.replace(/\D/g, '');
        updateUser({
            ...user,
            user_info: {
                ...user.user_info,
                phone_number: number
            }
        })
    }

    function departmentDataListOptions() {
        let options = [...departments, ...offices];
        options.sort();
        return (
            <>
                {options.map((option, index) => (
                    <option key={index} value={option}/>
                ))}
            </>
        )
    }

    // if(challenge == null){
    //     return null;
    // }
    return (
        challenge &&
        <UserConsumer>
            {({user, updateUser}) => (
                user &&
                <div>
                    <Header/>
                    <div className="main_wrapper">
                        <Helmet>
                            <title>BSU Writing Challenge | Register</title>
                        </Helmet>
                        <Announcement/>
                        <div className="left_column">
                            {/*TODO this phrasing could get weird based on challenge names. Discuss with Comm*/}
                            <h1 className="red-text">Register for the {challenge.name} Writing Challenge</h1>
                            <div className="header_wrapper">
                                {/* <img src={pencilIconSmall} className="pencil_small desktop_hide" alt=""/> */}
                                <h2 className="margin-bottom-small">Challenge Dates: <span
                                    className="dates">{getChallengeDateRange(challenge)}</span></h2>
                            </div>
                            <p className="margin-bottom-small">The challenge is open to faculty in every department.
                                Registration
                                closes on <Moment className="bold" date={challenge.registration_close_date}
                                                  format={'MMMM Do'}/>. You will be notified of your team assignment
                                when registration closes. </p>

                            <p className="margin-bottom-small">Fill out the form below to get started!</p>


                            <form className="margin-bottom-med" onSubmit={submitRegistration}>
                                <ul className="margin-bottom-med">
                                    <li>
                                        <ul>
                                            <li className="label-margin_small">
                                                <div>
                                                    <label htmlFor="firstName">First Name*</label>
                                                </div>
                                                <div>
                                                    <label htmlFor="lastName">Last Name*</label>
                                                </div>
                                            </li>
                                            <li>
                                                <input required type="text" className="mobile_form_margin_bottom"
                                                       name="first"
                                                       placeholder="First Name"
                                                       onChange={e => setUserProp("first_name", e.target.value)}
                                                       value={getUserProp('first_name')}
                                                />
                                                <input required type="text" className="mobile_form_margin_bottom"
                                                       name="last"
                                                       placeholder="Last Name"
                                                       onChange={e => updateUser({
                                                           ...user,
                                                           user_info: {
                                                               ...user.user_info,
                                                               last_name: e.target.value
                                                           }
                                                       })}
                                                       value={getUserProp('last_name')}
                                                />
                                            </li>
                                        </ul>

                                    </li>
                                    <li className="margin-top-small">
                                        <ul>
                                            <li className="label-margin_small">
                                                <label htmlFor="department=">Department*</label>
                                            </li>
                                            <li>

                                                <input required list={"departments"}
                                                       className="dropdown full_length  mobile_form_margin_bottom"

                                                       onChange={e => updateUser({
                                                           ...user,
                                                           user_info: {
                                                               ...user.user_info,
                                                               department: e.target.value
                                                           }
                                                       })}
                                                       value={getUserProp('department')}
                                                />
                                                <datalist id={"departments"} placeholder="Department">
                                                    {departmentDataListOptions()}
                                                </datalist>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="margin-top-small">
                                        <ul>
                                            <li className="label-margin_small">
                                                <div>
                                                    <label htmlFor="email">E-mail*</label>
                                                </div>
                                                <div className="mobile_form_hide">
                                                    <label htmlFor="phone">Phone*</label>
                                                </div>
                                            </li>
                                            <li>
                                                <input required readOnly type="email"
                                                       className="mobile_form_margin_bottom"
                                                       name="email" placeholder="E-mail"
                                                       onChange={e => updateUser({
                                                           ...user,
                                                           user_info: {
                                                               ...user.user_info,
                                                               email: e.target.value
                                                           }
                                                       })}
                                                       value={getUserProp('email')}
                                                />
                                                <div className="mobile_form_show">
                                                    <label htmlFor="phone">Campus Phone*</label>
                                                </div>
                                                <input required type="tel" className="mobile_form_margin_bottom"
                                                       placeholder="Phone"
                                                       name="phone"
                                                       onChange={e => updatePhoneNumber(e)}
                                                       value={getPhoneNumber()}
                                                />
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="margin-top-small">
                                        <ul>
                                            <li className="label-margin_small">
                                                <div>
                                                    <label htmlFor="building">Office Building*</label>
                                                </div>
                                                <div className="mobile_form_hide form">
                                                    <label htmlFor="room">Room #*</label>
                                                </div>
                                            </li>
                                            <li>
                                                <input required type="text" className="mobile_form_margin_bottom"
                                                       name="building"
                                                       placeholder="Building"
                                                       onChange={e => updateUser({
                                                           ...user,
                                                           user_info: {
                                                               ...user.user_info,
                                                               office_building: e.target.value
                                                           }
                                                       })}
                                                       value={getUserProp('office_building')}
                                                />
                                                <div>
                                                    <div className="mobile_form_show">
                                                        <label htmlFor="room">Room #</label>
                                                    </div>
                                                    <input type="text" name="room" placeholder="101"
                                                           className="room_number"
                                                           required
                                                           onChange={e => updateUser({
                                                               ...user,
                                                               user_info: {
                                                                   ...user.user_info,
                                                                   room_number: e.target.value
                                                               }
                                                           })}
                                                           value={getUserProp('room_number')}
                                                    />
                                                </div>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="margin-top-small">
                                        <ul>
                                            <li className="label-margin_small">
                                                <label htmlFor="department=">My Goal for this Challenge*</label>
                                            </li>
                                            <li>

                                                <input required type={"text"}
                                                       className="mobile_form_margin_bottom full_length"
                                                       placeholder={"I want to write every day!"}
                                                       onChange={e => setRegistration({
                                                           ...registration,
                                                           goal: e.target.value
                                                       })}
                                                       value={registration.goal}
                                                />
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                                <button onClick={e => ReactGA.event({
                                    category: 'Register',
                                    action: 'Click',
                                    label: challenge.name
                                })} className="register" type="submit">Register</button>
                            </form>
                        </div>
                    </div>
                    <Footer/>
                </div>
            )}
        </UserConsumer>
    );
}

export default Register;
